import { getVenueId } from "lenses/venueId";
import { get } from "utils/api";
import {
  isServiceWorkerSupported,
  isPushNotificationSupported,
  askUserPermission,
  startListening,
  resubscribeToPush,
  unsubscribeNotifications,
} from "utils/serviceworker";
import { getWaiter, getNotificationsActive } from "./reducer";
import { getDeviceId } from "../device";
import { getOrderState } from "../../lenses/payment";

export const savePayments = (payments) => ({
  type: "SAVE_PAYMENTS",
  payments,
});

export const setLoading = (state) => ({
  type: "SET_PAYMENTS_LOADING",
  state,
});

export const setNotificationsActive = (notifications_active) => ({
  type: "SET_NOTIFICATIONS_ACTIVE",
  notifications_active,
});

const _setWaiter = (waiter) => ({
  type: "SET_WAITER",
  waiter,
});

export const setWaiter = (waiter) => async (dispatch, getState) => {
  dispatch(_setWaiter(waiter));
  const { user } = getState();
  console.log("NOTI in actions: resubscribing due to setWaiter");
  await unsubscribeNotifications(user, getDeviceId(getState()));
  const notificationsActive = getNotificationsActive(getState());
  if (notificationsActive) {
    await resubscribeToPush();
  }
};

export const loadPayments = (waiter) => async (dispatch, getState) => {
  const { user } = getState();
  const venueId = getVenueId(user);
  try {
    dispatch(setLoading(true));
    const payments = await get("venue/$1/payments", [venueId])
      .query({
        limit: 10000,
        offset: 0,
        filter: {
          state: "payment succeeded",
        },
      })
      .auth(user)
      .on(
        { status: 403, error: "You don't have the rights to retrieve this." },
        () => {
          alert("You can not access this data. Please contact support.");
        }
      );
    const orders = await get("venue/$1/orders/$2", [
      venueId,
      payments.map((payment) => payment.orderId),
    ])
      .auth(user)
      .on(
        { status: 403, error: "You don't have the rights to retrieve this." },
        () => {
          alert("You can not access this data. Please contact support.");
        }
      );

    let mergedPayments = [];
    payments.forEach((payment) => {
      const orderOfPayment = orders.find(
        (order) => order.id === payment.orderId
      );
      mergedPayments.push({
        id: payment.id,
        orderId: payment.orderId,
        created: payment.created,
        table: orderOfPayment.table.name,
        total: payment.total,
        tip: payment.tip,
        currency: payment.currency,
        orderStatus: getOrderState(payment, orderOfPayment),
        waiter: orderOfPayment.waiter ? orderOfPayment.waiter : {},
      });
    });
    if (waiter !== "all") {
      mergedPayments = mergedPayments.filter(
        (payment) => payment.waiter.id === waiter
      );
    }
    dispatch(savePayments(mergedPayments));
    dispatch(setLoading(false));
  } catch (e) {
    e && alert(e);
    console.log(e);
  }
};

const processPushNotification = (dispatch, getState) => (/*data*/) => {
  dispatch(loadPayments(getWaiter(getState())));
};

export const enableNotifications = () => async (dispatch, getState) => {
  if (!isServiceWorkerSupported()) {
    alert("Your browser doesnt support push notifications.");
    return;
  }
  if (!isPushNotificationSupported()) {
    alert("Your browser doesnt support push notifications.");
    return;
  }
  if (!(await askUserPermission())) {
    alert("Permission denied.");
    return;
  }
  console.log("NOTI in actions: subscribing due to enable notis");
  await startListening({
    onPush: processPushNotification(dispatch, getState),
    getSubscribeParams: () => {
      const { user } = getState();
      const uuid = getDeviceId(getState());
      const waiter = getWaiter(getState());
      const res = {
        user,
        notificationsEnabled: getNotificationsActive(getState()),
        postBody: { uuid },
        delQuery: {
          user,
          uuid,
        },
      };
      if (waiter && waiter !== "all") {
        res.postBody.filter = { waiterId: waiter };
      }
      return res;
    },
  });
  dispatch(setNotificationsActive(true));
};

export const disableNotifications = () => async (dispatch, getState) => {
  const { user } = getState();
  console.log("NOTI in actions: unsubscribing due to disable");
  await unsubscribeNotifications(user, getDeviceId(getState()));
  dispatch(setNotificationsActive(false));
};

export default [
  setLoading().type,
  savePayments().type,
  setNotificationsActive().type,
  _setWaiter().type,
];
