import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  Feedbacks,
  Billings,
  BillingsPrint,
  BillingDetails,
  Payments,
  Login,
  ResetPassword,
  RequestPWReset,
  More,
  Help,
  OnboardPos,
} from "views";

import AppHeader from "view-shells/AppHeader/AppHeader";

export function AppContentPane() {
  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/payments" />
        </Route>

        <Route path="/login">
          <Login />
        </Route>

        <Route path="/resetpw">
          <ResetPassword />
        </Route>

        <Route path="/requestpwreset">
          <RequestPWReset />
        </Route>

        <Route path="/billingsprint">
          <BillingsPrint />
        </Route>

        <Route path="/onboard">
          <OnboardPos />
        </Route>

        <AppHeader className="sticky">
          <Route path="/billings">
            <Billings />
          </Route>
          <Route path="/billingdetails">
            <BillingDetails />
          </Route>
          <Route path="/feedbacks">
            <Feedbacks />
          </Route>
          <Route path="/payments">
            <Payments />
          </Route>
          <Route path="/more">
            <More />
          </Route>
          <Route path="/help">
            <Help />
          </Route>
        </AppHeader>
      </Switch>
    </div>
  );
}
