import { prop, pipe } from "ramda";

const INITIAL_STATE = { payments: [], waiter: "all" };

const getPaymentsState = prop("payments");
export const getPayments = pipe(getPaymentsState, prop("payments"));
export const getNotificationsActive = pipe(
  getPaymentsState,
  prop("notifications_active")
);
export const getWaiter = pipe(getPaymentsState, prop("waiter"));

const reducer =
  (types) =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case types.SAVE_PAYMENTS.name:
        return {
          ...state,
          payments: action.payments,
        };
      case types.SET_NOTIFICATIONS_ACTIVE.name:
        return {
          ...state,
          notifications_active: action.notifications_active,
        };
      case types.SET_WAITER.name:
        return {
          ...state,
          waiter: action.waiter,
        };
      case types.RESET.name:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export default reducer;
