import { Route, useRouteMatch } from "react-router-dom";

import { Untill } from "./untill";
import { SimplyDelivery } from "./simplyDelivery";
import { Gastrofix } from "./gastrofix";
import { Gastronovi } from "./gastronovi";
import { Orderbird } from "./orderbird";
import { OAuth } from "./oauth";
import { LeafSystems } from "./leafSystems";

export const OnboardPos = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route path={`${path}/pos/:posId/simplyDelivery`}>
        <SimplyDelivery />
      </Route>
      <Route path={`${path}/pos/:posId/gastrofix`}>
        <Gastrofix />
      </Route>
      <Route path={`${path}/pos/:posId/gastronovi`}>
        <Gastronovi />
      </Route>
      <Route path={`${path}/pos/:posId/untill`}>
        <Untill />
      </Route>
      <Route path={`${path}/pos/:posId/orderbird`}>
        <Orderbird />
      </Route>
      <Route path={`${path}/pos/:posId/leafSystems`}>
        <LeafSystems />
      </Route>
      <Route path={`${path}/oauth/:posType`}>
        <OAuth />
      </Route>
    </>
  );
};
