import { prop } from "ramda";

const INITIAL_STATE = [];

export const getFeedbacks = prop("feedbacks");

const reducer =
  (types) =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case types.SAVE_FEEDBACKS.name:
        return action.feedbacks;
      case types.RESET.name:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export default reducer;
