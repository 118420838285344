import React from "react";
import PropTypes from "prop-types";
import { DuButton } from "components/DuButton/DuButton";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { dateToText } from "utils";
import ReactStars from "react-rating-stars-component";
import { DuDatePicker } from "components/DuDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
import { printCurrency } from "utils/money";

const Component = ({
  page,
  previousPage,
  nextPage,
  feedbacks,
  date,
  updateDate,
  listRef,
}) => {
  return (
    <div className="full-screenable-node flex flex-col h-screen">
      <div className="flex mb-4 pt-4 justify-between">
        <div className="pb-4 flex-col justify-start">
          <p className="text-gray-700">Datum</p>
          <p className="mr-4">
            <DuDatePicker date={date} onChange={(d) => updateDate(d)} />
          </p>
        </div>

        <div className="pt-6 mb-6 flex justify-end pr-5">
          <p className="ml-4 ">
            <CSVLink
              data={feedbacks}
              filename={"feedbacks.csv"}
              className="btn btn-primary"
              target="_blank"
            >
              <DuButton>Export</DuButton>
            </CSVLink>
          </p>
          <p className="ml-3 ">
            <ReactToPrint
              trigger={() => <DuButton>Print</DuButton>}
              content={() => listRef.current}
            />
          </p>
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-md">
        <ul className="" ref={listRef}>
          {feedbacks !== undefined &&
            feedbacks.map((feedback, i) => (
              <li key={feedback.orderPaymentId}>
                <Link
                  to={
                    "/billingdetails?orderid=" +
                    feedback.orderId +
                    "&&paymentid=" +
                    feedback.orderPaymentId
                  }
                  className={
                    "block hover:bg-gray-200" +
                    (i % 2 === 0 ? "" : " bg-gray-100")
                  }
                >
                  <div className="px-4 py-4 sm:px-6">
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="flex text-black text-xl">
                        <b>{feedback.orderPaymentId}</b>
                        {"\u00A0\u00A0\u00A0"} {feedback.table}
                      </div>

                      <div className="flex w-38 px-8 h-10 -mt-4">
                        <p
                          key={feedback.orderPaymentId}
                          className={"mt-2 inline-flex"}
                        >
                          <ReactStars
                            value={feedback.rating}
                            size={30}
                            edit={false}
                          />
                        </p>
                      </div>

                      <div className="flex flex-1">
                        <p
                          key={feedback.orderPaymentId}
                          className={"mt-2 inline-flex text-gray-700"}
                        >
                          {feedback.feedback}
                        </p>
                      </div>

                      <div className="sm:ml-5 flex-shrink-0">
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <p>
                          <b>{dateToText(feedback.created).split(",")[1]}</b>
                          {"\u00A0\u00A0"}
                          {dateToText(feedback.created).split(",")[0]}
                        </p>
                      </div>

                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <p>
                          {printCurrency(feedback.total, feedback.currency)} ( +{" "}
                          {printCurrency(feedback.tip, feedback.currency)})
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
        </ul>
      </div>
      <div className="my-4 flex items-center justify-center">
        <DuButton onClick={previousPage}>Vorherige Seite</DuButton>
        <p className="px-10">Seite {page + 1}</p>
        <DuButton onClick={nextPage}>Nächste Seite</DuButton>
      </div>
    </div>
  );
};

Component.propTypes = {
  page: PropTypes.number,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  feedbacks: PropTypes.array,
  date: PropTypes.number,
  updateDate: PropTypes.func,
  listRef: PropTypes.object,
};

export default Component;
