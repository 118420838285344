import { sum } from "ramda";

export const getAmounts = (billings) => {
  const totalTotal = sum(billings.map((b) => b.total - b.refundedAmount)),
    totalFees = sum(billings.map((b) => b.fee));

  return {
    totalTotal,
    totalFees,
    totalTip: sum(
      billings.map((b) =>
        Math.max(0, Math.min(b.total - b.refundedAmount, b.tip))
      )
    ),
    totalTotalWOFees: totalTotal - totalFees,
    totalCurrency: billings[0]?.currency,
  };
};
