import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DuFormikSelect, DuButton } from "components";
import { UntillPaymentMethod } from "./types";

export const UntillPaymentMethodForm = ({
  setPaymentMethod,
  paymentMethods,
  loading,
}: {
  paymentMethods: UntillPaymentMethod[];
  setPaymentMethod: (opts: { paymentMethodId: string }) => Promise<void>;
  loading?: boolean;
}) => (
  <Formik
    initialValues={{ paymentMethodId: undefined }}
    validationSchema={Yup.object({
      paymentMethodId: Yup.string().required(
        "Bitte geben Sie eine Zahlungsmethode an"
      ),
    })}
    onSubmit={setPaymentMethod}
  >
    <Form>
      <p>
        Bitte geben Sie die Zahlungsmethode an, die von dango genutzt werden
        soll.
      </p>
      <br />
      <DuFormikSelect label="Zahlungsmethode" name="paymentMethodId">
        <option value={null}>Bitte auswählen</option>
        {paymentMethods.map((pm) => (
          <option key={pm.id} value={pm.id}>
            {pm.name}
          </option>
        ))}
      </DuFormikSelect>
      <br />
      <div className="flex justify-end">
        <DuButton type="submit" loading={loading} label="Speichern" />
      </div>
    </Form>
  </Formik>
);
