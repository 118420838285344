import { UntillLogin } from "./UntillLogin";
import { UntillPaymentMethodForm } from "./UntillPaymentMethodForm";
import { UntillPaymentMethod } from "./types";
import { SmallBody, Container, SmallHeading } from "components";

export enum Status {
  Login,
  PaymentMethod,
  Done,
}

export const UntillForm = ({
  submitLogin,
  setPaymentMethod,
  paymentMethods,
  loading,
  status,
}: {
  submitLogin: (creds: { username: string; password: string }) => Promise<void>;
  paymentMethods: UntillPaymentMethod[];
  setPaymentMethod: (opts: { paymentMethodId: string }) => Promise<void>;
  loading?: boolean;
  status?: Status;
}) => (
  <Container>
    <SmallHeading>Untill Einrichtung</SmallHeading>
    <SmallBody>
      {status === Status.Login && (
        <UntillLogin submitLogin={submitLogin} loading={loading} />
      )}
      {status === Status.PaymentMethod && (
        <UntillPaymentMethodForm
          paymentMethods={paymentMethods}
          setPaymentMethod={setPaymentMethod}
          loading={loading}
        />
      )}
      {status === Status.Done && (
        <p>
          Die Einrichtung ist abgeschlossen. In kürze werden wir Ihnen die
          QR-Codes für Ihre Tische zuschicken.
        </p>
      )}
    </SmallBody>
  </Container>
);
