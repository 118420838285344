import { get, put } from "utils/api";
import { getVenueId } from "lenses/venueId";
import { throttle } from "utils";

export const setLoading = (state) => ({
  type: "SET_WAITTIME_LOADING",
  state,
});

export const saveWaittime = (waittime) => ({
  type: "SET_WAITTIME",
  waittime,
});

export const loadWaittime = () => async (dispatch, getState) => {
  const { user } = getState();
  const venueId = getVenueId(user);
  try {
    dispatch(setLoading(true));

    const [response] = await get("venue/waittime/$1", [[venueId]])
      .auth(user)
      .on(
        { status: 403, error: "You don't have the rights to retrieve this." },
        () => {
          alert("You can not access this data. Please contact support.");
        }
      );
    dispatch(saveWaittime(response.waitTime));
    dispatch(setLoading(false));
  } catch (e) {
    e && alert(e);
    dispatch(setLoading(false));
  }
};

const throttledSetWaittime = throttle(
  async (waittime, dispatch, getState) => {
    const { user } = getState();
    const venueId = getVenueId(user);
    try {
      dispatch(setLoading(true));
      await put("venue/waittime/$1", [venueId])
        .data({ waitTime: waittime })
        .auth(user)
        .on(
          {
            status: 403,
            error: "You don't have the rights to retrieve this.",
          },
          () => {
            alert("You can not access this data. Please contact support.");
          }
        );
      dispatch(loadWaittime());
      dispatch(setLoading(false));
    } catch (e) {
      e && alert(e);
      dispatch(setLoading(false));
    }
  },
  2000,
  { leading: false }
);

export const setWaittime = (waittime) => async (dispatch, getState) => {
  dispatch(saveWaittime(waittime));
  throttledSetWaittime(waittime, dispatch, getState);
};

export default [saveWaittime().type, setLoading().type];
