import React, { useEffect } from "react";
import Component from "./BillingDetails";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getPayment } from "redux/payment/reducer";
import { loadPayment } from "redux/payment/actions";
import { prop } from "ramda";
import { getPaymentLoading } from "redux/loading/reducer";

export const BillingDetails = () => {
  const location = useLocation();
  const orderId = location.search.split("orderid=")[1].split("&&")[0];
  const paymentId = location.search.split("paymentid=")[1].split("&&")[0];

  const payment = useSelector(getPayment);
  const loading = useSelector(getPaymentLoading);

  const user = useSelector(prop("user"));
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.apiToken) {
      dispatch(loadPayment(orderId, paymentId));
    }
  }, [dispatch, user, orderId, paymentId]);
  return <Component payment={payment} loading={loading} />;
};
