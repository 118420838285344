import React from "react";
import { classNames } from "utils";

import PropTypes from "prop-types";

const Timeline = ({ items = [] }) => (
  <div className="flow-root">
    <ul role="list" className="-mb-8">
      {items.map((event, eventIdx) => (
        <li key={event.id}>
          <div className="relative pb-8">
            {eventIdx !== items.length - 1 ? (
              <span
                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />
            ) : null}
            <div className="relative flex space-x-3">
              <div>
                <span
                  className={classNames(
                    event.color,
                    "h-8 w-8 rounded-full flex items-center",
                    "justify-center ring-8 ring-white bg-white"
                  )}
                >
                  <event.icon className="h-6 w-6 " aria-hidden="true" />
                </span>
              </div>
              <div className="min-w-0 flex-1 pt-1.5">
                <div>
                  <p className="text-base text-gray-900">{event.content}</p>
                  {event.body && (
                    <p className="text-sm text-gray-900 py-2">{event.body}</p>
                  )}
                  <time
                    className="text-right text-sm whitespace-nowrap text-gray-500"
                    dateTime={event.datetime}
                  >
                    {event.date}
                  </time>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

Timeline.propTypes = {
  items: PropTypes.array,
};

export default Timeline;
