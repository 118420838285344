import { useHistory } from "react-router-dom";

import { utils } from "@apparts/login";

export const getRedirector = () => {
  const { search } = window.location;
  return utils.buildRedirector(search, ["login"]);
};

export const redirectToLogout = () => {
  const redirectQuery = getRedirector();
  window.location.href = "/login" + redirectQuery.str + "&logout=true";
};

export const useUser = () => {
  const history = useHistory();
  return utils.buildGetLoggedInUser(() => {
    const redirectQuery = getRedirector();
    history.push("/login" + redirectQuery.str);
  });
};

export const getRedirectUrl = utils.getRedirectUrl;
