const statusColor = (status, { noBg } = {}) =>
  ({
    ["payment succeeded"]: "text-red-800 " + (noBg ? "" : "bg-red-100"),
    ["payment aborted"]: "text-gray-800 " + (noBg ? "" : "bg-gray-100"),
    ["order in process"]: "text-yellow-800 " + (noBg ? "" : "bg-yellow-100"),
    ["order done"]: "text-green-800 " + (noBg ? "" : "bg-green-100"),
    ["erfolgt"]: "text-green-800 " + (noBg ? "" : "bg-green-100"),
    ["pending"]: "text-red-800 " + (noBg ? "" : "bg-red-100"),
    ["refunded"]: "text-gray-800 " + (noBg ? "" : "bg-gray-100"),
    ["!"]: "text-red-800 " + (noBg ? "" : "bg-red-100"),
    ["not fully payed"]: "text-yellow-800 " + (noBg ? "" : "bg-yellow-100"),
    ["fully payed"]: "text-green-800 " + (noBg ? "" : "bg-green-100"),
  }[status]);

const getVenueStati = (
  order = { states: [] },
  { allowedOrderStates = {} } = {}
) => order.states.filter(({ state }) => !!allowedOrderStates[state]?.venue);

const orderWasRefunded = (order = { states: [] }) =>
  order.states.filter(({ state }) => state === "payment refunded").length > 0;

const getBillingApiStateByUiState = (uiState) =>
  uiState === "erfolgt"
    ? "payment succeeded"
    : uiState === "pending"
    ? "payment intent created"
    : uiState === "refunding"
    ? "payment refunded"
    : "payment refunded";

export {
  statusColor,
  getVenueStati,
  orderWasRefunded,
  getBillingApiStateByUiState,
};
