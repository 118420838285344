import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DuFormikSelect, DuButton } from "components";

import { CostUnit } from "./types";

export type GastronoviParamsProps = {
  costUnits: CostUnit[];
  submitParams: (creds: { costunitId: string }) => Promise<void>;
  loading?: boolean;
};

export const GastronoviParams = (props: GastronoviParamsProps) => (
  <Formik
    initialValues={{ costunitId: "" }}
    validationSchema={Yup.object({
      costunitId: Yup.string().required(
        "Bitte geben Sie eine Kostenstelle an."
      ),
    })}
    onSubmit={props.submitParams}
  >
    <Form>
      <p>
        Bitte geben Sie die Kostenstelle an, welche von dango genutzt werden
        sollen.
      </p>
      <br />
      <DuFormikSelect label="Kostenstelle" name="costunitId">
        <option value={null}>Bitte auswählen</option>
        {props.costUnits.map((costUnit) => (
          <option key={costUnit.id} value={costUnit.id}>
            {costUnit.name}
          </option>
        ))}
      </DuFormikSelect>
      <br />
      <div className="flex justify-end">
        <DuButton type="submit" loading={props.loading} label="Speichern" />
      </div>
    </Form>
  </Formik>
);
