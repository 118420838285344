import React from "react";
import { useResetPassword } from "@apparts/login";
import { useLocation, useHistory } from "react-router-dom";
import { loginComponents } from "components";

import * as api from "utils/api";

export const ResetPassword = () => {
  const history = useHistory();
  const ResetPassword = useResetPassword({
    api,
    apiPrefix: "venue/user",
    components: loginComponents,
  });
  const { search } = useLocation(),
    welcome = new URLSearchParams(search).get("welcome"),
    email = new URLSearchParams(search).get("email"),
    token = new URLSearchParams(search).get("token");
  return (
    <div
      style={{
        maxWidth: 600,
        margin: "auto",
        padding: 20,
        marginTop: 100,
      }}
    >
      <ResetPassword
        welcome={welcome}
        email={email}
        token={token}
        onDone={() => {
          history.push("/");
        }}
      />
    </div>
  );
};
