import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DuFormikInput, DuButton } from "components";

export type SimplyDeliveryLoginType = {
  submitLogin: (creds: {
    username: string;
    password: string;
    endpoint: string;
  }) => Promise<void>;
  loading?: boolean;
};

export const SimplyDeliveryLogin = (props: SimplyDeliveryLoginType) => (
  <Formik
    initialValues={{
      username: "",
      password: "",
      endpoint: "",
    }}
    validationSchema={Yup.object({
      username: Yup.string().required(
        "Bitte geben Sie Ihren SimplyDelivery-Nuternamen ein"
      ),
      password: Yup.string().required(
        "Bitte geben Sie Ihr SimplyDelivery-Passwort ein"
      ),
      endpoint: Yup.string()
        .url()
        .required(
          "Bitte geben Sie Ihre SimplyDelivery-URL ein (z.B. 'https://sd8.simplypos.de')"
        )
        .matches(
          /^https:/,
          "Bitte überprüfen Sie die URL und stellen Sie sicher, dass Sie eine sichere URL eingegeben haben (https anstatt von http)."
        )
        .matches(
          /^((?!admin).)*$/,
          "Bitte Sie die URL zum Login an der Kasse ein (z.B. 'https://sd8.simplypos.de'), nicht die URL für den Administratorzugangs."
        ),
    })}
    onSubmit={props.submitLogin}
  >
    <Form>
      <p>
        Bitte konfigurieren Sie SimplyDelivery wie
        <a
          href="https://paywithdango.notion.site/SimplyDelivery-13aaa6ff63ff4db1b2fb5a4459e317d0"
          className="text-primary-600"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          in dieser Anleitung{" "}
        </a>
        beschrieben. Danach geben Sie unten bitte Ihre
        SimplyDelivery-Login-Daten an:
      </p>
      <br />
      <DuFormikInput name="username" label="SimplyDelivery-Nutzername" />
      <br />
      <DuFormikInput
        autocomplete="new-password"
        name="password"
        label="SimplyDelivery-Passwort"
        type={"password"}
      />
      <br />
      <DuFormikInput name="endpoint" label="SimplyDelivery-URL" />
      <br />
      <div className="flex justify-end">
        <DuButton type="submit" loading={props.loading} label="Weiter" />
      </div>
    </Form>
  </Formik>
);
