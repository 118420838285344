import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "utils";
import { put, patch } from "utils/api";
import { getVenueId } from "lenses/venueId";

import { OrderbirdResponse } from "./types";

import { OrderbirdForm, Status } from "./OrderbirdForm";

export const Orderbird = () => {
  const user = useUser();
  const venueId = getVenueId(user);
  const { posId }: { posId: string } = useParams();
  const [loading, setLoading] = useState(false);
  const [orderbirdData, setOrderbirdData] = useState(null);
  const [status, setStatus] = useState(Status.Login);

  const login = async ({ username, password }) => {
    setLoading(true);
    try {
      const response: OrderbirdResponse = await put(
        "venue/$1/pos/$2/orderbird",
        [venueId, posId]
      )
        .data({ username, password })
        .auth(user)
        .on({ status: 403, error: "Pos authentication failed" }, () => {
          alert(
            "Logindaten falsch! Bitte überprüfen Sie Nutzername und Passwort."
          );
        });
      setOrderbirdData(response);
      setStatus(Status.Params);
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
    }
    setLoading(false);
  };

  const sendParams = async (params: {
    userId: string;
    paymentMethodId: string;
  }) => {
    setLoading(true);
    try {
      await patch("venue/$1/pos/$2/orderbird", [venueId, posId])
        .data(params)
        .auth(user);
      setStatus(Status.Done);
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
    }
    setLoading(false);
  };

  return (
    <OrderbirdForm
      submitLogin={login}
      loading={loading}
      paymentMethods={orderbirdData?.paymentMethods}
      users={orderbirdData?.users}
      setParams={sendParams}
      status={status}
    />
  );
};
