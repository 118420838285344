import { prop } from "ramda";

const INITIAL_STATE = {};

export const getPayment = prop("payment");

const reducer =
  (types) =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case types.SAVE_PAYMENT.name:
        return action.payment;
      case types.RESET.name:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export default reducer;
