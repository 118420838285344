import { SmallBody, Container, SmallHeading } from "components";

import { GastrofixLogin, GastrofixLoginType } from "./GastrofixLogin";
import {
  GastrofixParamsForm,
  GastrofixParamsFormType,
} from "./GastrofixParams";

export enum Status {
  Login,
  Params,
  Done,
}

export const GastrofixForm = ({
  setParams,
  stores,
  setStoreId,
  paymentMethods,
  loading,
  status,
  submitLogin,
}: {
  status: Status;
} & GastrofixParamsFormType &
  GastrofixLoginType) => (
  <Container>
    <SmallHeading>Gastrofix Einrichtung</SmallHeading>
    <SmallBody>
      {status === Status.Login && (
        <GastrofixLogin submitLogin={submitLogin} loading={loading} />
      )}
      {status === Status.Params && (
        <GastrofixParamsForm
          paymentMethods={paymentMethods}
          stores={stores}
          setParams={setParams}
          loading={loading}
          setStoreId={setStoreId}
        />
      )}
      {status === Status.Done && (
        <p>
          Die Einrichtung ist abgeschlossen. In kürze werden wir Ihnen die
          QR-Codes für Ihre Tische zuschicken.
        </p>
      )}
    </SmallBody>
  </Container>
);
