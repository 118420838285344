import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage as FErrorMessage } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { classNames } from "utils";

const DuFormikSelect = ({ label, name, children, ...props }) => {
  const { disabled, description, containerClassName, ...rest } = props;
  return (
    <div className={containerClassName}>
      <label
        htmlFor="location"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="relative ">
        <Field
          as="select"
          id={name}
          name={name}
          disabled={disabled}
          className={classNames(
            "shadow-sm mt-1 block w-full text-base border-gray-300",
            "focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
            "sm:text-sm rounded-md",
            "pl-3 pr-10 py-2",
            disabled && "text-gray-400"
          )}
          {...rest}
        >
          {children}
        </Field>
        <FErrorMessage
          name={name}
          render={() => (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500 mr-6"
                aria-hidden="true"
              />
            </div>
          )}
        />
      </div>
      <FErrorMessage
        name={name}
        render={(msg) => (
          <p className="mt-2 text-sm text-red-600" id={name + "-error"}>
            {msg}
          </p>
        )}
      />
      {description && (
        <p className="mt-2 text-sm text-gray-500" id={name + "-description"}>
          {description}
        </p>
      )}
    </div>
  );
};
DuFormikSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export { DuFormikSelect };
