import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DuFormikSelect, DuButton } from "components";
import { OrderbirdPaymentMethod, OrderbirdUser } from "./types";

export const OrderbirdParamsForm = ({
  setParams,
  users,
  paymentMethods,
  loading,
}: {
  users: OrderbirdUser[];
  paymentMethods: OrderbirdPaymentMethod[];
  setParams: (opts: {
    paymentMethodId: string;
    userId: string;
  }) => Promise<void>;
  loading?: boolean;
}) => (
  <Formik
    initialValues={{ paymentMethodId: undefined, userId: undefined }}
    validationSchema={Yup.object({
      paymentMethodId: Yup.string().required(
        "Bitte geben Sie eine Zahlungsmethode an."
      ),
      userId: Yup.string().required("Bitte geben Sie einen Nutzer an."),
    })}
    onSubmit={setParams}
  >
    <Form>
      <p>
        Bitte geben Sie den Nutzer und die Zahlungsmethode an, welche von dango
        genutzt werden sollen.
      </p>
      <br />
      <DuFormikSelect label="Nutzer" name="userId">
        <option value={null}>Bitte auswählen</option>
        {users.map((user) => (
          <option key={user.uuid} value={user.uuid}>
            {user.name}
          </option>
        ))}
      </DuFormikSelect>
      <br />
      <DuFormikSelect label="Zahlungsmethode" name="paymentMethodId">
        <option value={null}>Bitte auswählen</option>
        {paymentMethods.map((pm) => (
          <option key={pm.uuid} value={pm.uuid}>
            {pm.name}
          </option>
        ))}
      </DuFormikSelect>
      <br />
      <div className="flex justify-end">
        <DuButton type="submit" loading={loading} label="Speichern" />
      </div>
    </Form>
  </Formik>
);
