import React from "react";
import PropTypes from "prop-types";
import { DuFormikInput } from "../DuFormikInput/DuFormikInput";
import { DuButton } from "../DuButton/DuButton";
import { Link as RRLink } from "react-router-dom";
const ErrorMessage = ({ message }) => (
  <p className="p-10 pb-0 mt-2 text-sm text-red-600">{message}</p>
);
ErrorMessage.propTypes = {
  message: PropTypes.string,
};

const FormikInput = (props) => (
  <div className="py-2">
    <DuFormikInput {...props} />
  </div>
);

const Button = ({ submit, ...props }) => (
  <div className="py-2 flex justify-end w-full">
    <DuButton type={submit ? "submit" : undefined} {...props} />
  </div>
);
Button.propTypes = {
  submit: PropTypes.any,
};

const Link = (props) => (
  <div className="py-2 text-primary-600 focus:text-primary-900">
    <RRLink {...props} />
  </div>
);

export const loginComponents = { FormikInput, Button, Link, ErrorMessage };
