import { Workbox } from "workbox-window";
import { post, del } from "../utils/api";
import { getVenueId } from "../lenses/venueId";

export const isServiceWorkerSupported = () => {
  return "serviceWorker" in navigator;
};

let workbox;
export const getServiceWorker = async () => {
  if (!workbox) {
    workbox = new Workbox("/sw.js");
    await workbox.register();
  }
  return workbox;
};

export const unregisterServiceWorker = async () => {
  const workbox = await getServiceWorker();
  await workbox.messageSW({ type: "UNREGISTER" });
};

export const isPushNotificationSupported = () => {
  return "PushManager" in window;
};

export const askUserPermission = async () => {
  return (await Notification.requestPermission()) === "granted";
};

const subscribeNotifications = async (getSubscribeParams, subscription) => {
  console.log("NOTI in serv.: subscribe");
  const { notificationsEnabled, user, postBody, delQuery } =
    getSubscribeParams();
  if (!notificationsEnabled) {
    return;
  }
  try {
    await unsubscribeNotifications(delQuery.user, delQuery.uuid);
    await post("venue/$1/notifications", [getVenueId(user)])
      .data({
        notification: JSON.parse(subscription),
        ...postBody,
      })
      .auth(user);
    console.log("service worker: subscribed to api");
  } catch (e) {
    console.log(
      "service worker: Could not subscribe service worker",
      e,
      "subscription",
      subscription
    );
    // disable push toggle
  }
};

export const unsubscribeNotifications = async (user, deviceId) => {
  console.log("NOTI in serv.: unsubscribe");
  try {
    await del("venue/$1/notifications", [getVenueId(user)])
      .auth(user)
      .query({ uuid: deviceId });
    console.log("service worker: unregistered from api");
  } catch (e) {
    console.log("service worker trying to unregister failed", e);
  }
};

export const resubscribeToPush = async () => {
  const workbox = await getServiceWorker();
  console.log("NOTI in serv.: resubscribe to push");
  await workbox.messageSW({
    type: "RESUBSCRIBE",
    params: { serverKey: process.env.REACT_APP_PUSH_PUBLIC_KEY },
  });
};

let hasEventListener = false;
export const startListening = async ({ onPush, getSubscribeParams }) => {
  const workbox = await getServiceWorker();
  console.log("NOTI in serv.: start lissening");
  workbox.messageSW({
    type: "SUBSCRIBE",
    params: { serverKey: process.env.REACT_APP_PUSH_PUBLIC_KEY },
  });
  if (!hasEventListener) {
    workbox.addEventListener("message", async (event) => {
      const data = event.data;
      switch (event.data.type) {
        case "PUSH":
          console.log("NOTI in serv.: PUSH NOTI RECEIVED");
          onPush();
          break;
        case "SERVICEWORKER INSTALLED":
          // does not work beacuse we don't yet have a listener for
          // the newly created service worker. Hence this message will
          // never be received.
          // console.log("NOTI in serv.: subscribing due to servicew. installed");
          console.log("NOTI in serv.: received servicew. installed");
          // await subscribeNotifications(getSubscribeParams, data.subscription);
          break;
        case "SUBSCRIBE ME":
          console.log("NOTI in serv.: subscribing due to subscribe me");
          await subscribeNotifications(getSubscribeParams, data.subscription);
          break;
      }
    });
    hasEventListener = true;
  }
};

export const stopListening = async (user) => {
  const workbox = await getServiceWorker();
  workbox.messageSW({ type: "UNSUBSCRIBE", params: { user } });
};
