import { SmallBody, Container, SmallHeading } from "components";

import { LeafSystemsLogin } from "./LeafSystemsLogin";
import {
  LeafSystemsParamsForm,
  LeafSystemsParamsFormType,
} from "./LeafSystemsParamsForm";

export enum Status {
  Login,
  Params,
  Done,
}

export const LeafSystemsForm = ({
  setParams,
  stores,
  setStoreId,
  paymentMethods,
  loading,
  status,
  submitLogin,
}: {
  submitLogin: (creds: { username: string; password: string }) => Promise<void>;
  status: Status;
} & LeafSystemsParamsFormType) => (
  <Container>
    <SmallHeading>LeafSystems Einrichtung</SmallHeading>
    <SmallBody>
      {status === Status.Login && (
        <LeafSystemsLogin submitLogin={submitLogin} loading={loading} />
      )}
      {status === Status.Params && (
        <LeafSystemsParamsForm
          paymentMethods={paymentMethods}
          stores={stores}
          setParams={setParams}
          loading={loading}
          setStoreId={setStoreId}
        />
      )}
      {status === Status.Done && (
        <p>
          Die Einrichtung ist abgeschlossen. In kürze werden wir Ihnen die
          QR-Codes für Ihre Tische zuschicken.
        </p>
      )}
    </SmallBody>
  </Container>
);
