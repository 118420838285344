import React from "react";
import PropTypes from "prop-types";
import { DuButton } from "components/DuButton/DuButton";
import { statusColor } from "lenses/status";
import { printCurrency } from "utils/money";
import { Link } from "react-router-dom";
import { DuActivityIndicator } from "components/DuActivityIndicator/DuActivityIndicator";
import { DuSelect } from "components/DuSelect";
import {
  getPaymentIconFromOrderState,
  getPosIconFromOrderState,
} from "../../lenses/payment";

const classNameTableHead =
  "table-cell px-2 py-3 text-left text-xs font-medium text-gray-700 uppercase " +
  " tracking-wider";
const classNameTableRow =
  "table-cell px-2 py-4 whitespace-nowrap text-sm text-gray-900 " +
  " font-bold align-middle";
const Component = ({
  payments,
  notificationsEnabled,
  toggleNotifications,
  loading,
  waiters,
  currentWaiter,
  updateWaiter,
}) => {
  return (
    <>
      <div className="flex flex-col h-screen -mt-8">
        <div className="m-2 mb-4">
          <div>
            <DuButton
              className="text-xl"
              bg={
                notificationsEnabled
                  ? "bg-indigo-700 text-white"
                  : "bg-gray-400"
              }
              onClick={toggleNotifications}
              label="Benachrichtigungen"
            />
          </div>
        </div>
        <div className="p-2 pb-4">
          <div className="flex flex-col justify-start">
            <p className="text-gray-700">Mitarbeiter</p>
            <p className="mr-4">
              <DuSelect
                items={waiters.map(
                  ({ firstName, lastName }) => `${firstName} ${lastName}`
                )}
                item={waiters.findIndex(({ id }) => id === currentWaiter)}
                onChange={(i) => updateWaiter(waiters[i])}
              />
            </p>
          </div>
        </div>

        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <div className="table min-w-full divide-y divide-gray-200">
                <div className="table-row-group bg-gray-50">
                  <div className="table-row">
                    <div className={classNameTableHead}>Zeit</div>
                    <div className={classNameTableHead}>Tisch</div>
                    <div className={classNameTableHead}>Betrag</div>
                    <div className={classNameTableHead}>
                      <img className="h-5 w-5" src="/money.png" />
                    </div>
                    <div className={classNameTableHead}>
                      <img className="h-5 w-5" src="/computer.png" />
                    </div>
                  </div>
                </div>
                <div className="table-row-group">
                  {!loading &&
                    payments.map((payment) => (
                      <Link
                        style={{ display: "table-row" }}
                        className={"bg-" + statusColor(payment.orderStatus)}
                        key={payment.id}
                        to={
                          "/billingdetails?orderid=" +
                          payment.orderId +
                          "&&paymentid=" +
                          payment.id
                        }
                      >
                        <div className={classNameTableRow}>
                          <span>
                            {new Date(payment.created).getHours()}:
                            {(
                              "0" + new Date(payment.created).getMinutes()
                            ).slice(-2)}
                          </span>
                        </div>
                        <div className={classNameTableRow}>{payment.table}</div>
                        <div className={classNameTableRow}>
                          {printCurrency(
                            payment.total - payment.tip,
                            payment.currency,
                            {
                              short: true,
                            }
                          )}
                          <br />
                          <span className="font-normal">
                            (+
                            {printCurrency(payment.tip, payment.currency, {
                              short: true,
                            })}
                            )
                          </span>
                        </div>
                        <div className={classNameTableRow}>
                          <img
                            className="w-5 h-5"
                            src={getPaymentIconFromOrderState(
                              payment.orderStatus
                            )}
                          />
                        </div>
                        <div className={classNameTableRow}>
                          <img
                            className="w-5 h-5"
                            src={getPosIconFromOrderState(payment.orderStatus)}
                          />
                        </div>
                      </Link>
                    ))}
                  {loading && (
                    <div className="mt-4 mb-4 flex justify-center">
                      <DuActivityIndicator local />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Component.propTypes = {
  payments: PropTypes.array,
  loadPayments: PropTypes.func,
  toggleNotifications: PropTypes.func,
  notificationsEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  waiters: PropTypes.array,
  currentWaiter: PropTypes.string,
  updateWaiter: PropTypes.func,
};

export default Component;
