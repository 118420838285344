// import {* as types} from './actions'
import { prop, pipe } from "ramda";

// console.error(venueConfigPojo);

const INITIAL_STATE = {};

const getLoadingState = prop("loading");
export const getPaymentsLoading = pipe(getLoadingState, prop("payments"));
export const getPaymentLoading = pipe(getLoadingState, prop("payment"));
export const getBillingsLoading = pipe(getLoadingState, prop("billings"));
export const getVenueLoading = pipe(getLoadingState, prop("venue"));

const reducer =
  (types) =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case types.SET_ORDERS_LOADING.name:
        return { ...state, orders: action.state };
      case types.SET_FEEDBACKS_LOADING.name:
        return { ...state, feedbacks: action.state };
      case types.SET_BILLINGS_LOADING.name:
        return { ...state, billings: action.state };
      case types.SET_PAYMENTS_LOADING.name:
        return { ...state, payments: action.state };
      case types.SET_PAYMENT_LOADING.name:
        return { ...state, payment: action.state };
      case types.SET_VENUE_LOADING.name:
        return { ...state, venue: action.state };
      case types.SET_WAITTIME_LOADING.name:
        return { ...state, waittime: action.state };
      case types.RESET.name:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export default reducer;
