import React, { Fragment } from "react";
import { connect } from "react-redux";

import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";

import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { DuActivityIndicator } from "components/DuActivityIndicator/DuActivityIndicator";

import { isUserAdmin, isUserWaiter } from "lenses/user";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AppHeader = ({ children, loading, user }) => {
  const location = useLocation();
  const navigation = [
    { name: "Kellner-Ansicht", href: "/payments" /*, role: "waiter"*/ },
    { name: "Abrechnungen", href: "/billings" },
    { name: "Feedback", href: "/feedbacks" },
    { name: "Hilfe", href: "/help" },
    { name: "Mehr", href: "/more" },
  ].map((elem) => ({ ...elem, current: elem.href === location.pathname }));

  const userHasPermission = (navigationItem) => {
    if (navigationItem.role === undefined) {
      return true;
    } else if (navigationItem.role === "waiter") {
      return isUserWaiter(user) || isUserAdmin(user);
    } else {
      return false;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="sticky top-0 z-50">
        <Disclosure as="nav" className="bg-white border-b border-gray-200 ">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex">
                    <div className="flex-shrink-0 flex items-center mr-3">
                      <img
                        className="block h-8 w-auto"
                        src="./dineup.png"
                        alt="Dineup"
                      />
                    </div>
                    <div className="hidden sm:-my-px sm:px-3 sm:flex">
                      {navigation.map((item) => {
                        return (
                          userHasPermission(item) && (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "border-primary-500 text-gray-900"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium sm:px-4"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          )
                        );
                      })}
                    </div>
                  </div>
                  <div className="ml-6 flex-1 flex items-center justify-end ">
                    <div className="px-6">
                      {loading && <DuActivityIndicator local />}
                    </div>
                  </div>
                  <div
                    className="hidden sm:ml-6 sm:flex
    sm:items-center sm:justify-center"
                  >
                    {/* right alligned stuff, that should be hidden in sandwich*/}
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        as={Link}
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-primary-50 border-primary-500 text-primary-700"
                            : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                          "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="flex items-center px-4 pt-3 border-t border-gray-200">
                    <Link
                      to={"/login?logout=true"}
                      className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <div className="py-10">
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            {/* CONTENT */}
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

AppHeader.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  user: PropTypes.object,
};

export default connect(
  ({ user, loading: { venue: venueLoading, orders, billings, payments } }) => ({
    user,
    loading: venueLoading || orders || billings || payments,
  }),
  {}
)(AppHeader);
