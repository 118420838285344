import React from "react";
import { useRequestPasswordReset } from "@apparts/login";
import { loginComponents } from "components";

import * as api from "utils/api";

export const RequestPWReset = () => {
  const RequestPWReset = useRequestPasswordReset({
    api,
    apiPrefix: "venue/user",
    components: loginComponents,
  });
  return (
    <div
      style={{
        maxWidth: 600,
        margin: "auto",
        padding: 20,
        marginTop: 100,
      }}
    >
      <RequestPWReset />
    </div>
  );
};
