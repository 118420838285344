import { getVenueId } from "lenses/venueId";
import { get } from "utils/api";
import { getStartOfDay } from "utils/time";

export const saveFeedbacks = (feedbacks) => ({
  type: "SAVE_FEEDBACKS",
  feedbacks,
});

export const setLoading = (state) => ({
  type: "SET_FEEDBACKS_LOADING",
  state,
});

export const loadFeedbacks =
  (page, startDate) => async (dispatch, getState) => {
    const { user } = getState();
    const venueId = getVenueId(user);
    try {
      dispatch(setLoading(true));
      const feedbacks = await get("venue/$1/feedback", [venueId])
        .query({
          filter: {
            created: {
              gt: getStartOfDay(startDate),
              lte: getStartOfDay(startDate + 1000 * 24 * 60 * 60 * 1.1),
            },
          },
          limit: 50,
          offset: page * 50,
          order: [
            {
              key: "created",
              dir: "DESC",
            },
          ],
          // filter attribute in api only takes one value
        })
        .auth(user)
        .on(
          { status: 403, error: "You don't have the rights to retrieve this." },
          () => {
            alert("You can not access this data. Please contact support.");
          }
        );
      const paymentIds = feedbacks.map((fb) => fb.orderPaymentId);
      const payments = await get("venue/$1/payments/$2", [venueId, paymentIds])
        .auth(user)
        .on(
          { status: 403, error: "You don't have the rights to retrieve this." },
          () => {
            alert("You can not access this data. Please contact support.");
          }
        );
      const mergedFeedbacks = feedbacks.map((feedback) => {
        const payment = payments.find(
          (payment) => payment.id === feedback.orderPaymentId
        );
        return {
          created: feedback.created,
          table: payment?.id,
          total: payment?.total,
          tip: payment?.tip,
          currency: payment?.currency,
          rating: feedback.userInfo.rating,
          feedback: feedback.userInfo.feedback,
          orderPaymentId: feedback.orderPaymentId,
          orderId: feedback.orderId,
        };
      });
      dispatch(saveFeedbacks(mergedFeedbacks));
      dispatch(setLoading(false));
    } catch (e) {
      e && alert(e);
      dispatch(setLoading(false));
    }
  };

export default [setLoading().type, saveFeedbacks().type];
