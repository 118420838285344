import React, { useEffect } from "react";

import Component from "./Payments";
import { useSelector, useDispatch } from "react-redux";
import {
  loadPayments,
  enableNotifications,
  disableNotifications,
  setWaiter,
} from "redux/payments/actions";
import {
  getNotificationsActive,
  getPayments,
  getWaiter,
} from "redux/payments/reducer";
import { getWaiters } from "redux/waiters/reducer";
import { loadWaiters } from "redux/waiters/actions";

export const Payments = () => {
  const dispatch = useDispatch();

  const currentWaiter = useSelector(getWaiter);
  const updateWaiter = (newFilter) =>
    dispatch(setWaiter(newFilter.id || "all"));

  const payments = useSelector(getPayments);

  const waiters = useSelector(getWaiters);

  const notificationsEnabled = useSelector(getNotificationsActive);

  const toggleNotifications = async () => {
    if (notificationsEnabled) {
      dispatch(disableNotifications());
    } else {
      dispatch(enableNotifications());
    }
  };

  useEffect(() => {
    dispatch(loadPayments(currentWaiter));
  }, [dispatch, currentWaiter]);

  useEffect(() => {
    const timeout = setInterval(() => {
      dispatch(loadPayments(currentWaiter));
    }, 1000 * 15);
    return () => clearInterval(timeout);
  }, [dispatch, currentWaiter]);

  useEffect(() => {
    if (notificationsEnabled) {
      dispatch(enableNotifications());
    }
    dispatch(loadWaiters());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Component
      payments={payments || []}
      notificationsEnabled={notificationsEnabled}
      toggleNotifications={toggleNotifications}
      waiters={[{ firstName: "Alle", lastName: "" }, ...waiters]}
      currentWaiter={currentWaiter}
      updateWaiter={updateWaiter}
    />
  );
};
