import { prop, propOr, pipe } from "ramda";

const INITIAL_STATE = [];

export const getBillings = pipe(prop("billings"), propOr([], "billings"));
export const getSummary = pipe(prop("billings"), prop("summary"));

const reducer =
  (types) =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case types.SAVE_BILLINGS.name:
        return action.billings;
      case types.RESET.name:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export default reducer;
