import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DuFormikInput, DuButton } from "components";

export type GastrofixLoginType = {
  submitLogin: (creds: {
    username: string;
    password: string;
    operatorId: string;
    authToken: string;
  }) => Promise<void>;
  loading?: boolean;
};

export const GastrofixLogin = (props: GastrofixLoginType) => (
  <Formik
    initialValues={{
      username: "",
      password: "",
      operatorId: "",
      authToken: "",
    }}
    validationSchema={Yup.object({
      username: Yup.string().required(
        "Bitte geben Sie Ihren Gastrofix-Nuternamen ein"
      ),
      password: Yup.string().required(
        "Bitte geben Sie Ihr Gastrofix-Passwort ein"
      ),
      operatorId: Yup.string().required(
        "Bitte geben Sie Ihre Gastrofix-Inhaber-Id ein"
      ),
      authToken: Yup.string().required(
        "Bitte geben Sie das Gastrofix-API-Token ein"
      ),
    })}
    onSubmit={props.submitLogin}
  >
    <Form>
      <p>
        Bitte konfigurieren Sie Gastrofix wie
        <a
          href="https://paywithdango.notion.site/Gastrofix-13aaa6ff63ff4db1b2fb5a4459e317d0"
          className="text-primary-600"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          in dieser Anleitung{" "}
        </a>
        beschrieben. Danach geben Sie unten bitte Ihre Gastrofix-Login-Daten an:
      </p>
      <br />
      <DuFormikInput name="username" label="Gastrofix-Nutzername" />
      <br />
      <DuFormikInput
        autocomplete="new-password"
        name="password"
        label="Gastrofix-Passwort"
        type={"password"}
      />
      <br />
      <DuFormikInput name="operatorId" label="Gastrofix-Inhaber-Id" />
      <br />
      <DuFormikInput name="authToken" label="Gastrofix-API-Token" />
      <br />
      <div className="flex justify-end">
        <DuButton type="submit" loading={props.loading} label="Weiter" />
      </div>
    </Form>
  </Formik>
);
