import React, { useEffect } from "react";
import Component from "./BillingsPrint";

import { useSelector } from "react-redux";
import { getBillings } from "redux/billings/reducer";
import { getWaiters } from "redux/waiters/reducer";
import { getAmounts } from "lenses/totalCalculation";

import { useLocation } from "react-router-dom";

export const BillingsPrint = () => {
  const waiters = useSelector(getWaiters);
  const billings = useSelector(getBillings);

  const { search = "" } = useLocation();
  const date = parseInt(new URLSearchParams(search).get("date") || 1) - 1;

  useEffect(() => {
    if (billings && waiters) {
      window.print();
      window.history.back();
    }
  }, [billings, waiters]);

  const { totalTotal, totalTip } = getAmounts(billings);

  const waitersWithTotal = waiters
    .map((waiter) => {
      const { totalTotal, totalTip, totalCurrency } = getAmounts(
        billings.filter(({ waiter: { id } }) => id === waiter.id)
      );
      return {
        ...waiter,
        total: totalTotal,
        tip: totalTip,
        currency: totalCurrency,
      };
    })
    .filter(({ total, tip }) => total !== 0 || tip !== 0);

  return (
    <Component
      date={date}
      billings={billings}
      waiters={waitersWithTotal}
      totalTotal={totalTotal}
      totalTip={totalTip}
    />
  );
};
