import React from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import { dateToText, classNames } from "utils";
import { CSVLink } from "react-csv";
import {
  DuSelect,
  DuButton,
  DuDatePicker,
  DuActivityIndicator,
} from "components";
import { statusColor } from "lenses/status";
import { Link } from "react-router-dom";
import { printCurrency } from "utils/money";
import { TabBar } from "./TabBar";
import { Stats } from "./Stats";
import { ChevronRightIcon } from "@heroicons/react/solid";

const classNameTableHead = classNames(
  "table-cell px-6 py-3 align-middle",
  "text-left text-xs font-medium text-gray-700",
  "uppercase tracking-wider"
);
const classNameTableRow =
  "table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-700";

const STATE_NAMES = {
  "payment succeeded": "Erfolgt",
  "payment refunded": "Refunded",
};

const Component = ({
  loading,
  date,
  updateDate,
  filterStates,
  currentFilter,
  updateFilter,
  billings,
  billingsForCSV,
  totalTotal,
  totalWOTip,
  totalTip,
  totalFeesWithTaxes,
  totalFeesWOTaxes,
  totalTotalWOFees,
  waiters,
  currentWaiter,
  updateWaiter,
  showStats,
  summary,
}) => {
  return (
    <div className="full-screenable-node flex flex-col ">
      <div className="flex mb-4 pt-4">
        <div className="pb-4 flex-col justify-start">
          <p className="text-gray-700">Datum</p>
          <div className="mr-4">
            <DuDatePicker date={date} onChange={(d) => updateDate(d)} />
          </div>
        </div>
        <div className="pb-4 flex-col justify-start items-center">
          <p className="text-gray-700">Mitarbeiter</p>
          <div className="mr-4">
            <DuSelect
              items={waiters.map(
                ({ firstName, lastName }) => `${firstName} ${lastName}`
              )}
              item={waiters.findIndex(({ id }) => id === currentWaiter)}
              onChange={(i) => updateWaiter(waiters[i])}
            />
          </div>
        </div>
      </div>

      <div className="relative pb-5 border-b border-gray-200 sm:pb-0 mb-5">
        <div className="pb-2 md:flex md:items-center md:justify-between">
          <div
            className="mt-3 flex md:mt-0 md:absolute md:top-3
        md:right-0 gap-2"
          >
            <CSVLink
              data={billingsForCSV}
              filename={"billings.csv"}
              className="btn btn-primary"
              target="_blank"
            >
              <DuButton>Export</DuButton>
            </CSVLink>
            <Link to={"/billingsprint?date=" + date}>
              <DuButton>Print</DuButton>
            </Link>
          </div>
        </div>

        <TabBar
          tabs={filterStates.map((state) => STATE_NAMES[state])}
          currentIndex={Math.max(0, filterStates.indexOf(currentFilter))}
          onTabClick={(i) => updateFilter(filterStates[i])}
        />
      </div>

      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div className="table min-w-full divide-y divide-gray-200">
              <div className="table-row-group bg-gray-50">
                <div className="table-row">
                  <div className={classNameTableHead}>Datum</div>
                  <div className={classNameTableHead}>Uhrzeit</div>
                  <div className={classNameTableHead}>Mitarbeiter</div>
                  <div className={classNameTableHead}>Tisch</div>
                  <div className={classNameTableHead}>Status</div>
                  <div className={classNameTableHead}>Rechnungsbetrag</div>
                  <div className={classNameTableHead}>Trinkgeld</div>
                  <div className={classNameTableHead}>Gesamt</div>
                  <div className={classNameTableHead}>
                    Gebühren (exkl. Ust.)
                  </div>
                  <div className={classNameTableHead}>
                    Gebühren (inkl. Ust.)
                  </div>
                  <div className={classNameTableHead}>
                    Betrag abzgl. Gebühren
                  </div>
                  <div className={classNameTableHead}>Bestellnummer</div>
                  <div className={classNameTableHead}>
                    Vorgangsnummer (dineup)
                  </div>
                  <div className={classNameTableHead} />
                </div>
              </div>
              {loading && (
                <div className="p-4">
                  <DuActivityIndicator local />
                </div>
              )}
              {!loading && (
                <div className="table-row-group">
                  {billings.map((billing, i) => (
                    <Link
                      to={
                        "/billingdetails?orderid=" +
                        billing.orderId +
                        "&&paymentid=" +
                        billing.id
                      }
                      key={billing.id}
                      style={{ display: "table-row" }}
                      className={i % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <div className={classNameTableRow}>
                        {dateToText(billing.created).split(",")[0]}
                      </div>
                      <div className={classNameTableRow}>
                        {dateToText(billing.created).split(",")[1]}
                      </div>
                      <div className={classNameTableRow}>
                        {billing.waiter.firstName} {billing.waiter.lastName}
                      </div>
                      <div className={classNameTableRow}>{billing.table}</div>
                      <div className={classNameTableRow}>
                        {billing.states.map((state, i) => (
                          <p
                            key={i}
                            className={
                              "px-2 m-2 inline-flex text-xs leading-5 font-semibold rounded-full " +
                              statusColor(state)
                            }
                          >
                            {state}
                          </p>
                        ))}
                      </div>
                      <div className={classNameTableRow}>
                        {printCurrency(billing.amount, billing.currency)}
                      </div>
                      <div className={classNameTableRow}>
                        {printCurrency(billing.tip, billing.currency)}
                      </div>
                      <div className={classNameTableRow}>
                        {printCurrency(billing.total, billing.currency)}
                      </div>
                      <div className={classNameTableRow}>
                        {printCurrency(billing.fee.feeWOTax, billing.currency)}
                      </div>
                      <div className={classNameTableRow}>
                        {printCurrency(
                          billing.fee.feeWithTax,
                          billing.currency
                        )}
                      </div>
                      <div className={classNameTableRow}>
                        {printCurrency(
                          billing.totalWithoutFee,
                          billing.currency
                        )}
                      </div>
                      <div className={classNameTableRow}>{billing.orderId}</div>
                      <div className={classNameTableRow}>{billing.id}</div>
                      <div>
                        <ChevronRightIcon className="mx-4 w-4 h-4" />
                      </div>
                    </Link>
                  ))}
                  <div className="table-row">
                    <div className={classNameTableRow}>
                      <b>{billings.length} Zahlungen</b>
                    </div>
                    <div className={classNameTableRow}></div>
                    <div className={classNameTableRow}></div>
                    <div className={classNameTableRow}></div>
                    <div className={classNameTableRow}></div>
                    <div className={classNameTableRow}>
                      <b>{printCurrency(totalWOTip, billings[0]?.currency)}</b>
                    </div>
                    <div className={classNameTableRow}>
                      <b>{printCurrency(totalTip, billings[0]?.currency)}</b>
                    </div>
                    <div className={classNameTableRow}>
                      <b>{printCurrency(totalTotal, billings[0]?.currency)}</b>
                    </div>
                    <div className={classNameTableRow}>
                      <b>
                        {printCurrency(totalFeesWOTaxes, billings[0]?.currency)}
                      </b>
                    </div>
                    <div className={classNameTableRow}>
                      <b>
                        {printCurrency(
                          totalFeesWithTaxes,
                          billings[0]?.currency
                        )}
                      </b>
                    </div>
                    <div className={classNameTableRow}>
                      <b>
                        {printCurrency(totalTotalWOFees, billings[0]?.currency)}
                      </b>
                    </div>
                    <div className={classNameTableRow}></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showStats && (
        <div className="mt-5">
          <Stats
            title="KPIs"
            stats={[
              { name: "Zahlungen gesamt", stat: summary.numberOfPayments },
              { name: "Bestellungen gesamt", stat: summary.numberOfOrders },
              {
                name: "ø Zahlung",
                stat: printCurrency(summary.averageValue.payment, "EUR"),
              },
              {
                name: "ø Tisch",
                stat: printCurrency(summary.averageValue.order, "EUR"),
              },
              {
                name: "Trinkgeldquote (bereinigt)",
                stat: Math.round(summary.tip.quotaCleaned) + "%",
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

Component.propTypes = {
  date: PropTypes.number,
  updateDate: PropTypes.func,
  filterStates: PropTypes.array,
  updateFilter: PropTypes.func,
  currentFilter: PropTypes.string,
  billings: PropTypes.array,
  billingsForCSV: PropTypes.array,
  totalTotal: PropTypes.number,
  totalWOTip: PropTypes.number,
  totalTip: PropTypes.number,
  totalFeesWithTaxes: PropTypes.number,
  totalFeesWOTaxes: PropTypes.number,
  totalTotalWOFees: PropTypes.number,
  waiters: PropTypes.array,
  currentWaiter: PropTypes.string,
  updateWaiter: PropTypes.func,
  loading: PropTypes.bool,
  summary: PropTypes.object,
  showStats: PropTypes.bool,
};

export default Component;
