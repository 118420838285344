import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

export const useURLParam = (name, defaultVal) => {
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const defaultValueMemoed = useMemo(() => defaultVal, []);
  const history = useHistory();
  const { search = "" } = useLocation();
  const page = parseInt(
    new URLSearchParams(search).get(name) || defaultValueMemoed
  );
  const setPage = (val) => {
    const params = new URLSearchParams(search);
    params.set(name, val);
    history.replace({ search: params.toString() });
  };
  return [page, setPage];
};

export default useURLParam;
