import React, { useEffect, useState } from "react";
import { MoreView } from "./View";
import { getVenueLoading } from "redux/loading";
import { loadVenue, getVenue } from "redux/venue";
import { useDispatch, useSelector } from "react-redux";
import { DuActivityIndicator } from "components";
import { getUser } from "lenses/user";
import { getVenueId } from "lenses/venueId";
import { post } from "utils/api";

export const More = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getVenueLoading);
  const [leaveShiftLoading, setLeaveShiftLoading] = useState(false);
  const venue = useSelector(getVenue);
  const user = useSelector(getUser);
  const venueId = getVenueId(user);

  useEffect(() => {
    dispatch(loadVenue());
  }, [dispatch]);

  const canLeaveShift = venue.pos?.leaveShiftButton;
  const leaveShift = async () => {
    setLeaveShiftLoading(true);
    try {
      await post("venue/$1/pos/$2/leaveShift", [venueId, venue.pos?.id]).auth(
        user
      );
      alert("Schicht erfolgreich verlassen");
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
    }
    setLeaveShiftLoading(false);
  };

  if (loading) {
    return <DuActivityIndicator />;
  }
  return (
    <MoreView
      leaveShift={canLeaveShift && leaveShift}
      leaveShiftLoading={leaveShiftLoading}
    />
  );
};
