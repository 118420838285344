import { getUserDataFromApiToken } from "@apparts/login";

const getVenueId = (user) => {
  if (user) {
    return getUserDataFromApiToken(user).venueId || 0;
  }
  return 0;
};

export { getVenueId };
