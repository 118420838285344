import React from "react";
import PropTypes from "prop-types";
import { DuActivityIndicator } from "../DuActivityIndicator/DuActivityIndicator";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function DuButton(props) {
  const {
    label,
    children,
    onClick,
    className = "",
    disabled,
    loading,
    bg,
    ...rest
  } = props;
  return (
    <button
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      type="button"
      className={classNames(
        disabled || loading
          ? "inline-flex h-10 items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          : "inline-flex h-10 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500",
        className,
        disabled
          ? "text-gray-500 bg-white"
          : bg
          ? bg
          : "text-white bg-primary-600"
      )}
      {...rest}
    >
      {loading && (
        <div className="absolute ">
          <DuActivityIndicator local />
        </div>
      )}
      <div className={loading ? "opacity-0" : ""}>{label || children}</div>
    </button>
  );
}

DuButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  bg: PropTypes.string,
  type: PropTypes.string,
};
