import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DuActivityIndicator } from "components";

export const Help = () => {
  const history = useHistory();
  useEffect(() => {
    history.goBack();
    window.location =
      "https://paywithdango.notion.site/FAQ-dango-bd8f67b959184d4db2b3593ab1c1961f";
  }, [history]);

  return (
    <div>
      <DuActivityIndicator />
    </div>
  );
};
