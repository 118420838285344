const INITIAL_STATE = null;

const reducer =
  (types) =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case types.SET_WAITTIME.name:
        return action.waittime;
      case types.RESET.name:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export default reducer;
