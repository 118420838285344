import { prop, pipe } from "ramda";

const INITIAL_STATE = { waiters: [] };

const getWaitersState = prop("waiters");
export const getWaiters = pipe(getWaitersState, prop("waiters"));

const reducer =
  (types) =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case types.SAVE_WAITERS.name:
        return {
          waiters: action.waiters,
        };
      case types.RESET.name:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export default reducer;
