import React from "react";
import PropTypes from "prop-types";
import { classNames } from "utils";

export const TabBar = ({ tabs, currentIndex, onTabClick }) => (
  <div className="mt-4">
    <div className="sm:hidden">
      <label htmlFor="current-tab" className="sr-only">
        Select a tab
      </label>
      <select
        id="current-tab"
        name="current-tab"
        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
        defaultValue={tabs.find((tab, i) => i === currentIndex).name}
      >
        {tabs.map((tab, i) => (
          <option onClick={() => onTabClick(i)} key={tab}>
            {tab}
          </option>
        ))}
      </select>
    </div>
    <div className="hidden sm:block">
      <nav className="-mb-px flex space-x-8">
        {tabs.map((tab, i) => (
          <button
            onClick={() => onTabClick(i)}
            key={tab}
            className={classNames(
              i === currentIndex
                ? "border-primary-500 text-primary-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
            )}
            aria-current={i === currentIndex ? "page" : undefined}
          >
            {tab}
          </button>
        ))}
      </nav>
    </div>
  </div>
);

TabBar.propTypes = {
  tabs: PropTypes.array.isRequired,
  currentIndex: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
};
