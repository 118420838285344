import { getUserDataFromApiToken } from "@apparts/login";
import { prop } from "ramda";

export const getUserFromToken = (token) => {
  const user = getUserDataFromApiToken({ apiToken: token });
  if (user) {
    return { apiToken: token, email: user.email, id: user.id, fromToken: true };
  }
  return null;
};

export const isUserWaiter = (user) => {
  if (user) {
    return getUserDataFromApiToken(user).waiter;
  }
  return null;
};

export const isUserAdmin = (user) => {
  if (user) {
    return getUserDataFromApiToken(user).admin;
  }
  return null;
};

export const getUser = prop("user");
