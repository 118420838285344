const {
  REACT_APP_PRODUCTION_HOST,
  REACT_APP_AWS_DEV_API,
  REACT_APP_PRODUCTION_API,
} = process.env;

function isProdEnv() {
  return (
    getNodeEnvString() === "production" &&
    location.host === REACT_APP_PRODUCTION_HOST
  );
}

function isDevEnv() {
  return (
    getNodeEnvString() === "development" ||
    location.host !== REACT_APP_PRODUCTION_HOST
  );
}

function getApiUrl() {
  if (isProdEnv()) {
    return REACT_APP_PRODUCTION_API;
  } else if (
    location.host === "localhost:3000" ||
    location.host === "localhost:3001"
  ) {
    return "http://localhost:3000/v/";
  } else {
    return REACT_APP_AWS_DEV_API;
  }
}

/**
 *
 * note: process.env.REACT_APP_NODE_ENV is replaced at build-time, not runtime
 * @returns {string}
 */
function getNodeEnvString() {
  /* eslint-disable-next-line no-undef */
  return process.env.NODE_ENV;
}

export { isProdEnv, isDevEnv, getApiUrl };
