import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DuFormikSelect, DuButton } from "components";
import { GastrofixPaymentMethod, GastrofixStores } from "./types";

export type GastrofixParamsFormType = {
  stores: GastrofixStores[];
  paymentMethods: GastrofixPaymentMethod[];
  setStoreId: (storeId: string) => unknown;
  setParams: (opts: {
    paymentMethodId: string;
    storeId: string;
  }) => Promise<void>;
  loading?: boolean;
};

export const GastrofixParamsForm = ({
  setStoreId,
  setParams,
  stores,
  paymentMethods,
  loading,
}: GastrofixParamsFormType) => {
  return (
    <Formik
      initialValues={{ paymentMethodId: undefined, storeId: undefined }}
      validationSchema={Yup.object({
        paymentMethodId: Yup.string().required(
          "Bitte geben Sie eine Zahlungsmethode an."
        ),
        storeId: Yup.string().required("Bitte geben Sie einen Nutzer an."),
      })}
      onSubmit={setParams}
    >
      <Form>
        <p>
          Bitte geben Sie den Nutzer und die Zahlungsmethode an, welche von
          dango genutzt werden sollen.
        </p>
        <br />
        <DuFormikSelect
          label="Store"
          name="storeId"
          validate={(value: string) => {
            setStoreId(value);
          }}
        >
          <option value={""}>Bitte auswählen</option>
          {stores.map((store) => (
            <option key={store.id} value={store.id}>
              {store.name}
            </option>
          ))}
        </DuFormikSelect>
        <br />
        <DuFormikSelect
          label="Zahlungsmethode"
          name="paymentMethodId"
          disabled={!paymentMethods || loading}
        >
          <option value={null}>Bitte auswählen</option>
          {paymentMethods &&
            paymentMethods.map((pm) => (
              <option key={pm.id} value={pm.id}>
                {pm.name}
              </option>
            ))}
        </DuFormikSelect>
        <br />
        <div className="flex justify-end">
          <DuButton type="submit" loading={loading} label="Speichern" />
        </div>
      </Form>
    </Formik>
  );
};
