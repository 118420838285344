import React from "react";
import { Provider } from "react-redux";
import configureStore from "@apparts/redux";

import defaultStore from "./defaultStore";
import venue from "./venue";
import orders from "./orders";
import feedbacks from "./feedbacks";
import payment from "./payment";
import billings from "./billings";
import payments from "./payments";
import waiters from "./waiters";
import loading from "./loading";
import waittime from "./waittime";
import { loginStore } from "@apparts/login";
import device from "./device";

// eslint-disable-next-line
const { store, persistor, types } = configureStore({
  defaultStore,
  venue,
  orders,
  feedbacks,
  payment,
  billings,
  payments,
  waiters,
  loading,
  waittime,
  device,
  ...loginStore,
});

const withStore = (app) => <Provider store={store}>{app}</Provider>;
export { persistor, withStore };
export default store;
