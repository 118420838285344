import { useEffect, useState } from "react";
import { useUser } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { loadVenue, getVenue } from "redux/venue";
import {
  Container,
  DuActivityIndicator,
  SmallBody,
  SmallHeading,
} from "components";

export const OAuth = () => {
  useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const { posType }: { posType: string } = useParams();
  const venue: { pos?: { id: string; type: string } } = useSelector(getVenue);

  useEffect(() => {
    dispatch(loadVenue());
  }, [dispatch]);

  const [posNotFound, setPosNotFound] = useState(false);

  useEffect(() => {
    if (venue?.pos && history) {
      if (venue.pos.type === posType) {
        history.push(`../pos/${venue.pos.id}/${posType}`);
      } else {
        setPosNotFound(true);
      }
    }
  }, [history, posType, venue]);

  if (posNotFound) {
    return (
      <Container>
        <SmallHeading>POS Einrichtung</SmallHeading>
        <SmallBody>
          Die OAuth Einrichting ist erfolgreich abgeschlossen. Bitte
          kontaktieren Sie Ihren dango-Ansprechpartner um die POS-Einrichtung
          abzuschließen.
        </SmallBody>
      </Container>
    );
  }
  return <DuActivityIndicator />;
};
