import { prop, pipe } from "ramda";

const INITIAL_STATE = { id: 0 };

export const getVenue = prop("venue");
export const getVenueLoading = pipe(prop("loading"), prop("venue"));

const reducer =
  (types) =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case types.SET_VENUE.name:
        return action.venue;
      case types.RESET.name:
        return INITIAL_STATE;
      default:
        return state;
    }
  };

export default reducer;
