import { useState } from "react";
import { useUser } from "utils";
import { put } from "utils/api";
import { useParams } from "react-router-dom";
import { getVenueId } from "lenses/venueId";

import { UntillForm, Status } from "./UntillView";
import { UntillPaymentMethod } from "./types";

export const Untill = () => {
  const user = useUser();
  const { posId }: { posId: string } = useParams();
  const venueId = getVenueId(user);

  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [status, setStatus] = useState(Status.Login);

  const login = async ({ username, password }) => {
    setLoading(true);
    try {
      const response: UntillPaymentMethod[] = await put(
        "venue/$1/pos/$2/untill",
        [venueId, posId]
      )
        .data({ username, password })
        .auth(user)
        .on({ status: 403, error: "Pos authentication failed" }, () => {
          alert(
            "Logindaten falsch! Bitte überprüfen Sie Nutzername und Passwort."
          );
        });
      setPaymentMethods(response);
      setStatus(Status.PaymentMethod);
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
    }
    setLoading(false);
  };

  const setPaymentMethod = async ({ paymentMethodId }) => {
    setLoading(true);
    try {
      await put("venue/$1/pos/$2/untill/paymentMethod", [venueId, posId])
        .data({ paymentMethodId })
        .auth(user);
      setStatus(Status.Done);
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
    }
    setLoading(false);
  };

  return (
    <UntillForm
      submitLogin={login}
      paymentMethods={paymentMethods}
      setPaymentMethod={setPaymentMethod}
      loading={loading}
      status={status}
    />
  );
};
