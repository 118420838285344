import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DuFormikInput, DuButton } from "components";

export const UntillLogin = (props: {
  submitLogin: (creds: { username: string; password: string }) => Promise<void>;
  loading?: boolean;
}) => (
  <Formik
    initialValues={{ username: "", password: "" }}
    validationSchema={Yup.object({
      username: Yup.string().required(
        "Bitte geben Sie Ihren Untill-Nuternamen ein"
      ),
      password: Yup.string().required(
        "Bitte geben Sie Ihr Untill-Passwort ein"
      ),
    })}
    onSubmit={props.submitLogin}
  >
    <Form>
      <p>
        Bitte konfigurieren Sie Untill wie
        <a
          href="https://paywithdango.notion.site/Untill-6f728c05bc3a4249b4bd7965e04ba563"
          className="text-primary-600"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          in dieser Anleitung{" "}
        </a>
        beschrieben. Danach geben Sie unten bitte die Login-Daten des neu
        angelegten Nutzers an:
      </p>
      <br />
      <DuFormikInput name="username" label="Untill-Nutzername" />
      <br />
      <DuFormikInput
        autocomplete="new-password"
        name="password"
        label="Untill-Passwort"
        type={"password"}
      />
      <br />
      <div className="flex justify-end">
        <DuButton type="submit" loading={props.loading} label="Weiter" />
      </div>
    </Form>
  </Formik>
);
UntillLogin.propTypes = {
  submitLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
