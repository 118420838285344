import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/outline";

/* eslint-disable-next-line react/prop-types */
const CustomInput = ({ value, onClick }, ref) => (
  <div className="relative">
    <input
      className="w-full mt-1 block border pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
    />
    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </div>
  </div>
);

export const DuDatePicker = ({ date, onChange }) => {
  const Input = forwardRef(CustomInput);
  return (
    <DatePicker
      selected={date}
      onChange={(d) => onChange(d)}
      customInput={<Input />}
    />
  );
};
DuDatePicker.propTypes = {
  date: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
