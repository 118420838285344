import React, { useState, useEffect, useCallback } from "react";
import Component from "./Billings";

import { useSelector, useDispatch } from "react-redux";
import { getBillingsLoading } from "redux/loading/reducer";
import { getBillings, getSummary } from "redux/billings/reducer";
import { loadBillings } from "redux/billings/actions";
import { getWaiters } from "redux/waiters/reducer";
import { loadWaiters } from "redux/waiters/actions";
import { useURLParam } from "utils/hooks";
import { prop } from "ramda";

const FILTER_STATES = ["payment succeeded", "payment refunded"];

export const Billings = () => {
  const setDateObj = (date) => setDate(date.getTime());
  const [date, setDate] = useURLParam("date", Date.now());

  const [stateFilter, setStateFilter] = useState(FILTER_STATES[0]);
  const updateFilter = (newFilter) => setStateFilter(newFilter);

  const [currentWaiter, setCurrentWaiter] = useState("all");
  const updateWaiter = (newFilter) => setCurrentWaiter(newFilter.id || "all");

  const user = useSelector(prop("user"));
  const loading = useSelector(getBillingsLoading);
  const billings = useSelector(getBillings);
  const summary = useSelector(getSummary);
  const waiters = useSelector(getWaiters);
  const billingsForCSV = billings.map((billing) => ({
    ...billing,
    created: new Date(billing.created),
    amount: billing.amount / 100,
    tip: billing.tip / 100,
    total: billing.total / 100,
    fee: billing.fee / 100,
    totalWithoutFee: billing.totalWithoutFee / 100,
    waiter: billing.waiter
      ? billing.waiter.firstName + " " + billing.waiter.lastName
      : "",
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadWaiters());
  }, [dispatch]);

  const load = useCallback(
    async (stateFilter, date, currentWaiter) => {
      dispatch(loadBillings(stateFilter, date, currentWaiter));
    },
    [dispatch]
  );
  useEffect(() => {
    if (user.apiToken) {
      load(stateFilter, date, currentWaiter);
    }
  }, [load, user, stateFilter, date, currentWaiter]);

  return (
    <Component
      loading={loading}
      date={date}
      updateDate={setDateObj}
      filterStates={FILTER_STATES}
      updateFilter={updateFilter}
      currentFilter={stateFilter}
      billings={billings}
      showStats={
        !loading &&
        stateFilter === FILTER_STATES[0] &&
        summary?.numberOfPayments > 0
      }
      totalTotal={summary?.total?.totalWithTip}
      totalWOTip={summary?.total?.total}
      totalTip={summary?.tip?.total}
      totalFeesWithTaxes={summary?.fees?.total}
      totalFeesWOTaxes={summary?.fees?.totalWOTaxes}
      totalTotalWOFees={summary?.total?.totalPayout}
      waiters={[{ firstName: "Alle", lastName: "" }, ...waiters]}
      currentWaiter={currentWaiter}
      updateWaiter={updateWaiter}
      billingsForCSV={billingsForCSV}
      summary={summary}
    />
  );
};
