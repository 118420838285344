import { useState } from "react";
import { put, patch } from "utils/api";
import { useParams } from "react-router-dom";
import { getVenueId } from "lenses/venueId";
import { GastronoviForm } from "./View";
import { useUser } from "utils";

import { GastronoviResponse, Status } from "./types";

export const Gastronovi = () => {
  const user = useUser();
  const { posId }: { posId: string } = useParams();
  const venueId = getVenueId(user);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(Status.Login);
  const [data, setData] = useState(null);
  const submitLogin = async ({ username, password }) => {
    setLoading(true);
    try {
      const response: GastronoviResponse = await put(
        "venue/$1/pos/$2/gastronovi",
        [venueId, posId]
      )
        .data({ username, password })
        .auth(user);
      setData(response);
      setStatus(Status.Params);
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
    }
    setLoading(false);
  };

  const submitParams = async (params: { costunitId: string }) => {
    setLoading(true);
    try {
      await patch("venue/$1/pos/$2/gastronovi", [venueId, posId])
        .data(params)
        .auth(user);
      setStatus(Status.Done);
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
    }
    setLoading(false);
  };

  return (
    <GastronoviForm
      submitLogin={submitLogin}
      loading={loading}
      status={status}
      costUnits={data?.costUnits}
      submitParams={submitParams}
    />
  );
};
