import { getVenueId } from "lenses/venueId";
import { get } from "utils/api";

export const saveWaiters = (waiters) => ({
  type: "SAVE_WAITERS",
  waiters,
});

export const loadWaiters = () => async (dispatch, getState) => {
  const { user } = getState();
  const venueId = getVenueId(user);
  try {
    const waiters = await get("venue/$1/waiters", [venueId])
      .auth(user)
      .on(
        { status: 403, error: "You don't have the rights to retrieve this." },
        () => {
          alert("You can not access this data. Please contact support.");
        }
      );
    dispatch(saveWaiters(waiters.sort((a, b) => a.firstName > b.firstName)));
  } catch (e) {
    e && alert(e);
  }
};

export default [saveWaiters().type];
