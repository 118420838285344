import useURLParam from "./useURLParam";

export const usePaginated = (load) => {
  const [pageRaw, setPageRaw] = useURLParam("page", 1);
  const page = parseInt(pageRaw || 1) - 1;
  const setPage = (page) => setPageRaw(page + 1);

  const nextPage = () => {
    load && load(page + 1);
    setPage(page + 1);
  };
  const previousPage = () => {
    const newPage = Math.max(0, page - 1);
    load && load(newPage);
    setPage(newPage);
  };

  return { nextPage, previousPage, page, setPage };
};

export default usePaginated;
