export type CostUnit = {
  id: string;
  name: string;
};

export type GastronoviResponse = {
  costUnits: CostUnit[];
};

export enum Status {
  Login,
  Params,
  Done,
}
