import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "utils";
import { get, put, patch } from "utils/api";
import { getVenueId } from "lenses/venueId";

import {
  SimplyDeliveryStoresResponse,
  SimplyDeliveryPaymentMethod,
} from "./types";

import { SimplyDeliveryForm, Status } from "./SimplyDeliveryForm";

export const SimplyDelivery = () => {
  const user = useUser();
  const venueId = getVenueId(user);
  const { posId }: { posId: string } = useParams();
  const [loading, setLoading] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [stores, setStores] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [status, setStatus] = useState(Status.Login);

  const login = async ({ username, password, endpoint: endpointRaw }) => {
    setLoading(true);
    try {
      const endpoint = endpointRaw.split("/").slice(0, 3).join("/");
      const response: SimplyDeliveryStoresResponse = await put(
        "venue/$1/pos/$2/simplyDelivery",
        [venueId, posId]
      )
        .data({
          username,
          password,
          endpoint,
        })
        .auth(user)
        .on({ status: 403, error: "Pos authentication failed" }, () => {
          alert(
            "Logindaten falsch! Bitte überprüfen Sie Nutzername und Passwort."
          );
        });
      setStores(
        response.businesses.map((name) => ({
          id: name,
          name,
        }))
      );
      setStatus(Status.Params);
    } catch (e) {
      e && alert(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getPaymentMethods = async () => {
      setLoading(true);
      try {
        const paymentMethods: SimplyDeliveryPaymentMethod = await get(
          "venue/$1/pos/$2/simplyDelivery/store/$3",
          [venueId, posId, storeId]
        ).auth(user);
        setPaymentMethods(paymentMethods);
      } catch (e) {
        e && alert(e);
      }
      setLoading(false);
    };
    if (storeId !== "") {
      getPaymentMethods();
    } else {
      setPaymentMethods(null);
    }
  }, [user, venueId, posId, storeId]);

  const sendParams = async (params: {
    storeId: string;
    paymentMethodId: string;
  }) => {
    setLoading(true);
    console.log(params);
    try {
      await patch("venue/$1/pos/$2/simplyDelivery", [venueId, posId])
        .data(params)
        .auth(user);
      setStatus(Status.Done);
    } catch (e) {
      e && alert(e);
    }
    setLoading(false);
  };

  return (
    <SimplyDeliveryForm
      submitLogin={login}
      loading={loading}
      paymentMethods={paymentMethods}
      stores={stores}
      setParams={sendParams}
      status={status}
      setStoreId={setStoreId}
    />
  );
};
