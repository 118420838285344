import { useApi, Token, Request } from "@apparts/api";
import { getApiUrl } from "./envHelper";
import { redirectToLogout } from "./user";

const URL = getApiUrl();

class MyToken extends Token {
  constructor(user) {
    super(user);

    // Tell Token where to find the users api token, should you
    // already have one
    this._apiToken = user.apiToken;
  }

  async renew() {
    if (!this._user.fromToken) {
      super.renew();
    }
  }

  async renewAPIToken(user) {
    // Tell Token how to renew the API Token
    try {
      const apiToken = await get("venue/user/apiToken").authPW(
        user.email,
        user.loginToken
      );
      return apiToken;
    } catch (e) {
      redirectToLogout();
      return null;
    }
  }

  static getUserKey(user = {}) {
    // Tell Token how to identify users
    return user.email;
  }
}

class MyRequest extends Request {
  getURL(apiVersion) {
    // Tell Request what the URL prefix is
    return URL + apiVersion;
  }

  getAPIVersion() {
    // Tell Request what the default APIVersion is
    return 1;
  }

  online() {
    // Will be called, when a network-call succeded
  }

  auth(user) {
    if (user && (user.email || user.apiToken)) {
      this._auth = MyToken.getAPIToken(user);
    }
    return this;
  }

  async middleware() {
    this.on(0, () => {
      // on offline
    });
    this.on(401, () => {
      redirectToLogout();
    });
  }
}

/* eslint-disable-next-line react-hooks/rules-of-hooks */
const { get, put, patch, post, del } = useApi(MyRequest);
export { get, put, patch, post, del };
