import React, { useEffect, useCallback } from "react";
import { useLogin } from "@apparts/login";
import { useLocation, useHistory } from "react-router-dom";
import { resetStore } from "redux/defaultStore/actions";
import { useSelector, useDispatch } from "react-redux";
import { loginComponents } from "components";
import { actions } from "@apparts/login";
import { getRedirectUrl } from "utils";
import { getUserFromToken } from "lenses/user";

// Import your configured @apparts/api here:
import * as api from "utils/api";

export const Login = () => {
  const { search } = useLocation(),
    logout = new URLSearchParams(search).get("logout"),
    token = new URLSearchParams(search).get("token"),
    redirect = new URLSearchParams(search).get("redirect"),
    rsearch = new URLSearchParams(search).get("rsearch"),
    history = useHistory();
  const dispatch = useDispatch();

  const onLogout = useCallback(() => {
    const search = new URLSearchParams();
    if (redirect) {
      search.set("redirect", redirect);
    }
    if (rsearch) {
      search.set("rsearch", rsearch);
    }
    history.push("/login?" + search.toString());
    dispatch(resetStore());
  }, [dispatch, history, redirect, rsearch]);

  const user = useSelector((store) => store.user);

  const onLogin = useCallback(() => {
    if (redirect) {
      history.push(getRedirectUrl(redirect, rsearch));
    } else {
      history.push("/");
    }
  }, [history, redirect, rsearch]);

  useEffect(() => {
    if (token) {
      dispatch(actions.login(getUserFromToken(token)));
      onLogin();
    }
  }, [onLogin, dispatch, token]);

  useEffect(() => {
    if (logout && !user.id) {
      onLogout();
    }
  }, [onLogout, user, logout]);

  const Login = useLogin({
    api,
    apiPrefix: "venue/user",
    components: loginComponents,
  });
  return (
    <div
      style={{
        maxWidth: 600,
        margin: "auto",
        padding: 20,
        marginTop: 100,
      }}
    >
      <Login
        pwForgottenUrl="/requestpwreset"
        onLogin={() => onLogin()}
        onLogout={() => onLogout()}
        logMeOut={!!logout}
      />
    </div>
  );
};
