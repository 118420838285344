import { getVenueId } from "lenses/venueId";
import { get } from "utils/api";

export const saveBillings = (billings) => ({
  type: "SAVE_BILLINGS",
  billings,
});

export const setLoading = (state) => ({
  type: "SET_BILLINGS_LOADING",
  state,
});

export const loadBillings =
  (stateFilter, date, waiter) => async (dispatch, getState) => {
    const { user } = getState();
    const venueId = getVenueId(user);
    const queryParams = {
      limit: 10000,
      offset: 0,
      startTime: date,
      endTime: date,
      filter: {
        waiterId: waiter !== "all" ? waiter : undefined,
        state: stateFilter,
      },
    };

    try {
      dispatch(setLoading(true));
      const { payments, ...summary } = await get("venue/$1/payments", [venueId])
        .v(2)
        .query(queryParams)
        .auth(user)
        .on(
          { status: 403, error: "You don't have the rights to retrieve this." },
          () => {
            alert("You can not access this data. Please contact support.");
          }
        );
      const orders = await get("venue/$1/orders/$2", [
        venueId,
        payments.map((payment) => payment.orderId),
      ])
        .auth(user)
        .on(
          { status: 403, error: "You don't have the rights to retrieve this." },
          () => {
            alert("You can not access this data. Please contact support.");
          }
        );

      const stateMap = {
        "payment intent created": "pending",
        "payment succeeded": "erfolgt",
        "payment confirmed": "erfolgt",
        "payment refunded": "refunded",
      };

      let billings = [];
      payments.forEach((payment) => {
        const refundStates = payment.states.filter(
          ({ state }) => state === "payment refunded"
        );
        const orderOfPayment = orders.find(
          (order) => order.id === payment.orderId
        );
        billings.push({
          created: payment.created,
          table: orderOfPayment.table.name || "",
          refunded: refundStates.length > 0,
          refundedAmount: refundStates.reduce(
            (a, { data }) => a + data.refundedAmount,
            0
          ),
          states:
            payment.states.filter((state) => state.data?.failure).length !== 0
              ? [stateMap[payment.state], "!"]
              : [stateMap[payment.state]],
          amount: payment.total - payment.tip,
          tip: payment.tip,
          total: payment.total,
          fee: payment.fee || {},
          totalWithoutFee: payment.total - payment.fees,
          orderId: payment.orderId,
          id: payment.id,
          currency: payment.currency,
          waiter: orderOfPayment.waiter ? orderOfPayment.waiter : {},
        });
      });
      if (waiter !== "all") {
        billings = billings.filter((billing) => billing.waiter.id === waiter);
      }
      dispatch(saveBillings({ billings, summary }));
      dispatch(setLoading(false));
    } catch (e) {
      e && alert(e);
    }
  };

export default [setLoading().type, saveBillings().type];
