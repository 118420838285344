import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DuFormikInput, DuButton } from "components";

export type GastronoviLoginProps = {
  submitLogin: (creds: { username: string; password: string }) => Promise<void>;
  loading?: boolean;
};

export const GastronoviLogin = (props: GastronoviLoginProps) => (
  <Formik
    initialValues={{ username: "", password: "" }}
    validationSchema={Yup.object({
      username: Yup.string().required(
        "Bitte geben Sie Ihren Gastronovi-Nuternamen ein"
      ),
      password: Yup.string().required(
        "Bitte geben Sie Ihr Gastronovi-Passwort ein"
      ),
    })}
    onSubmit={props.submitLogin}
  >
    <Form>
      <p>
        Bitte konfigurieren Sie Gastronovi wie
        <a
          href="https://paywithdango.notion.site/Gastronovi-en-538d7250e179445e83535f8832012a18"
          className="text-primary-600"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          in dieser Anleitung{" "}
        </a>
        beschrieben. Danach geben Sie unten bitte die Login-Daten des neu
        angelegten Nutzers an:
      </p>
      <br />
      <DuFormikInput name="username" label="Gastronovi-Nutzername" />
      <br />
      <DuFormikInput
        autocomplete="new-password"
        name="password"
        label="Gastronovi-Passwort"
        type={"password"}
      />
      <br />
      <div className="flex justify-end">
        <DuButton type="submit" loading={props.loading} label="Speichern" />
      </div>
    </Form>
  </Formik>
);
