// we may want to add internationalization here later on
export const printCurrency = (value, currency, { short } = {}) => {
  switch (currency) {
    case "EUR":
      return (
        (parseFloat(value) / 100).toFixed(2).replace(".", ",") +
        (short ? "" : " €")
      );
    default:
      return value;
  }
};
