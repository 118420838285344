import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DuButton } from "components";

export const MoreView = ({ leaveShift, leaveShiftLoading }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="max-w-3xl mx-auto">
      {leaveShift && (
        <div className="bg-white overflow-hidden shadow rounded-lg my-8">
          <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Schicht verlassen
            </h3>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <DuButton loading={leaveShiftLoading} onClick={leaveShift}>
              Schicht verlassen
            </DuButton>
          </div>
        </div>
      )}
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Account
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <Link to={"/login?logout=true"}>
            <DuButton>Logout</DuButton>
          </Link>
        </div>
      </div>
      <div className="flex gap-4 pt-16 justify-end">
        <a href="https://www.paywithdango.com/impressum">Impressum</a>
        <a href="https://www.paywithdango.com/privacy-policy">Datenschutz</a>
      </div>
    </div>
  </div>
);
MoreView.propTypes = {
  leaveShift: PropTypes.func,
  leaveShiftLoading: PropTypes.bool,
};
