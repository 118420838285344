import { ReactNode } from "react";

export const SmallBody = (props: { children: ReactNode }) => (
  <div className="px-4 py-5 sm:p-6 max-w-lg mx-auto">{props.children}</div>
);

export const SmallHeading = (props: { children: ReactNode }) => (
  <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 ">
    <h3 className="text-lg leading-6 font-medium text-gray-900 max-w-lg mx-auto px-4 sm:px-6">
      {props.children}
    </h3>
  </div>
);

export const Heading = (props: { children: ReactNode }) => (
  <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
    <h3 className="text-lg leading-6 font-medium text-gray-900">
      {props.children}
    </h3>
  </div>
);

export const Container = (props: { children: ReactNode }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-12">
    <div className="max-w-3xl mx-auto">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        {props.children}
      </div>
    </div>
  </div>
);
