export const getOrderState = (payment, orderOfPayment) =>
  payment.states.filter(({ data } = {}) => (data || {}).failure === true)
    .length > 0
    ? "!"
    : orderOfPayment.fullyPayed
    ? "fully payed"
    : "not fully payed";

export const getPaymentIconFromOrderState = (orderState) =>
  ({
    "fully payed": "/green.png",
    "not fully payed": "/partly.png",
    "!": "/question.png",
  }[orderState]);

export const getPosIconFromOrderState = (orderState) =>
  ({
    "!": "/exclamation.png",
  }[orderState] || "/check1.png");

export const getPaymentTextFromOrderState = (orderState) =>
  ({
    "fully payed": "Tisch komplett bezahlt",
    "not fully payed": "Tisch teilweise bezahlt. Tisch noch offen.",
    "!": "Zahlung eingegangen, bitte Summe prüfen.",
  }[orderState]);

export const getPosTextFromOrderState = (orderState) =>
  ({
    "!": "Kasse konnte nicht benachrichtigt werden. Items nicht boniert.",
  }[orderState] || "Kasse benachrichtigt. Items boniert.");
