import React from "react";
import PropTypes from "prop-types";

// Needed to keep tailwind happy. It needs to see the actualy string
// in the code, otherwise it would purge the css class away.
const gridColsList = [
  "md:grid-cols-0",
  "md:grid-cols-1",
  "md:grid-cols-2",
  "md:grid-cols-3",
  "md:grid-cols-4",
  "md:grid-cols-5",
  "md:grid-cols-6",
  "md:grid-cols-7",
  "md:grid-cols-8",
  "md:grid-cols-9",
];

export const Stats = ({ stats, title }) => {
  return (
    <div className="mt-16">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      <dl
        className={`mt-5 grid grid-cols-1 ${
          gridColsList[stats.length]
        } rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x`}
      >
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-primary-600">
                {item.stat}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
Stats.propTypes = {
  stats: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
