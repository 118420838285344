import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContentPane } from "view-shells/AppContentPane/AppContentPane";
import { Provider } from "react-redux";
import defaultStore from "redux/store";
import { persistor as defaultPersistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";

const App = ({
  history,
  store = defaultStore,
  persistor = defaultPersistor,
}) => {
  // {HistoryRouter} allows to inject an history-API object for testing purposes
  // (as opposed to BrowserRouter, which uses the browsers history API)

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router history={history}>
          {/*debug*/}
          {/*<nav className="fixed bottom-0 w-64 float-right m-10 opacity-50 r-0 b-0 bg-white p-2">*/}
          {/*  <Link to="/dineup-landing">Landing</Link> |*/}
          {/*  <Link to="/venue-welcome">Venue</Link>|*/}
          {/*  <Link to="/order-process">Main</Link>|*/}
          {/*  /!*<Link to="/order-process">Beverage</Link>|*!/*/}
          {/*  /!*<Link to="/menu-index-step">Dessert</Link>|*!/*/}
          {/*  <Link to="/menu-item-details">Itemdetails</Link>|*/}
          {/*  <Link to="/basket">Basket</Link>|*/}
          {/*  <Link to="/order-confirmation">Orderconfirmation</Link>*/}
          {/*</nav>*/}

          <AppContentPane />
        </Router>
      </PersistGate>
    </Provider>
  );
};

App.propTypes = {
  history: PropTypes.object,
  store: PropTypes.object,
  persistor: PropTypes.object,
};

export default App;
