import { getVenueId } from "lenses/venueId";
import { get } from "utils/api";
import { getOrderState } from "../../lenses/payment";

export const savePayment = (payment) => ({
  type: "SAVE_PAYMENT",
  payment,
});

export const setLoading = (state) => ({
  type: "SET_PAYMENT_LOADING",
  state,
});

export const loadPayment =
  (orderId, paymentId) => async (dispatch, getState) => {
    const { user } = getState();
    const venueId = getVenueId(user);
    try {
      dispatch(setLoading(true));
      const orderPayments = await get("venue/$1/order/$2/payment", [
        venueId,
        orderId,
      ])
        .query({
          limit: 50,
          offset: 0,
        })
        .auth(user)
        .on(
          { status: 403, error: "You don't have the rights to retrieve this." },
          () => {
            alert("You can not access this data. Please contact support.");
          }
        );
      let payment = orderPayments.find((payment) => payment.id == paymentId);
      const orders = await get("venue/$1/orders/$2", [
        venueId,
        orderPayments.map((payment) => payment.orderId),
      ])
        .auth(user)
        .on(
          { status: 403, error: "You don't have the rights to retrieve this." },
          () => {
            alert("You can not access this data. Please contact support.");
          }
        );
      const order = orders[0];
      payment = {
        states: payment.states.map((st) => ({
          id: st.id,
          state: st.state,
          message: st.message || "",
          failure: !!st.data?.failure,
          done: st.state === "payment succeeded",
          created: st.created,
        })),
        total: payment.total,
        tip: payment.tip,
        fee: payment.fees,
        paymentMethod: payment.paymentMethod,
        paymentProcessorId: payment.paymentProcessorId,
        invoiceId: payment.invoiceId,
        currency: payment.currency,
        id: payment.id,
        orderId: payment.orderId,
        partialOrder: payment.partialOrder,
        orderStatus: getOrderState(payment, order),
        order,
      };
      dispatch(savePayment(payment));
      dispatch(setLoading(false));
    } catch (e) {
      e && alert(e);
    }
  };

export default [setLoading().type, savePayment().type];
