import React from "react";
import PropTypes from "prop-types";

export const DuSelect = ({ items, item, onChange }) => {
  return (
    <select
      onChange={(event) => onChange(parseInt(event.target.value))}
      value={item}
      id="amount"
      name="amount"
      className="mt-1 block  pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
    >
      {items.map((item, i) => (
        <option key={i} value={i}>
          {item}
        </option>
      ))}
    </select>
  );
};
DuSelect.propTypes = {
  items: PropTypes.array.isRequired,
  item: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
