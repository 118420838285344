import { SmallHeading, SmallBody, Container } from "components";
import { GastronoviLogin, GastronoviLoginProps } from "./Login";
import { GastronoviParams, GastronoviParamsProps } from "./Params";
import { Status } from "./types";

export const GastronoviForm = (
  props: {
    status: Status;
  } & GastronoviLoginProps &
    GastronoviParamsProps
) => (
  <Container>
    <SmallHeading>Gastronovi Einrichtung</SmallHeading>
    <SmallBody>
      {props.status === Status.Login && <GastronoviLogin {...props} />}
      {props.status === Status.Params && <GastronoviParams {...props} />}
      {props.status === Status.Done && (
        <p>
          Die Einrichtung ist abgeschlossen. In kürze werden wir Ihnen die
          QR-Codes für Ihre Tische zuschicken.
        </p>
      )}
    </SmallBody>
  </Container>
);
