import React from "react";
import PropTypes from "prop-types";
import { printCurrency } from "utils/money";
import { dateToText } from "utils";

const Component = ({
  billings = [],
  waiters = [],
  totalTotal,
  totalTip,
  date,
}) => {
  return (
    <div className="text-sm">
      <p>DINEUP - KASSENBERICHT</p>
      <br />
      <p>Datum: {new Date(date).toLocaleDateString()}</p>
      <p>gedruckt um {new Date().toLocaleString()}</p>
      <br />
      <br />
      <br />
      <p>ÜBERSICHT</p>
      <br />
      <p># Umsatz (U)</p>
      <p>{printCurrency(totalTotal - totalTip, billings[0]?.currency)}</p>
      <br />
      <p># Trinkgeld (T)</p>
      <p>{printCurrency(totalTip, billings[0]?.currency)}</p>
      <br />
      <p># Gesamt (G)</p>
      <p>{printCurrency(totalTotal, billings[0]?.currency)}</p>
      <br />
      <br />
      <br />
      <p>NACH MITARBEITER</p>
      <br />
      {waiters.map((waiter) => (
        <>
          <p>
            # {waiter.firstName} {waiter.lastName}
          </p>
          <p>
            U: {printCurrency(waiter.total, waiter.currency)} +{" "}
            {printCurrency(waiter.tip, waiter.currency)}
          </p>
          <br />
        </>
      ))}
      <br />
      <br />
      <br />
      <p>NACH UHRZEIT</p>
      <br />
      {billings.map((billing) => (
        <>
          <p>
            # {dateToText(billing.created).split(",")[1]} Uhr / {billing.table}{" "}
            / {billing.id}
          </p>
          <p>
            U: {printCurrency(billing.total, billing.currency)} +{" "}
            {printCurrency(billing.tip, billing.currency)}
          </p>
          <br />
        </>
      ))}
    </div>
  );
};

Component.propTypes = {
  billings: PropTypes.array,
  waiters: PropTypes.array,
  totalTotal: PropTypes.number,
  totalTip: PropTypes.number,
  date: PropTypes.number.isRequired,
};

export default Component;
