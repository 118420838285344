import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Component from "./Feedbacks";
import { getFeedbacks } from "redux/feedbacks/reducer";
import { loadFeedbacks } from "redux/feedbacks/actions";
import { useURLParam, usePaginated } from "utils/hooks";

export const Feedbacks = () => {
  const { page, nextPage, previousPage } = usePaginated();

  const setDateObj = (date) => setDate(date.getTime());
  const [date, setDate] = useURLParam("date", Date.now());

  const feedbacks = useSelector(getFeedbacks);

  const dispatch = useDispatch();

  useEffect(() => {
    const load = (page, startDate, endDate) =>
      dispatch(loadFeedbacks(page, startDate, endDate));
    load(page, date);
  }, [dispatch, page, date]);

  const listRef = useRef();

  return (
    <Component
      page={page}
      previousPage={previousPage}
      nextPage={nextPage}
      feedbacks={feedbacks}
      date={date}
      updateDate={setDateObj}
      listRef={listRef}
    />
  );
};
