import { v4 as uuidv4 } from "uuid";
import { prop, pipe } from "ramda";

const INITIAL_STATE = { deviceId: uuidv4() };

const getDeviceState = prop("device");
export const getDeviceId = pipe(getDeviceState, prop("deviceId"));

const reducer =
  () =>
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      default:
        return state;
    }
  };

export default {
  reducer,
  actionNames: [],
};
