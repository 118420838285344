import React from "react";
import PropTypes from "prop-types";
import { dateToText, labelify } from "utils";
import { printCurrency } from "utils/money";
import {
  InformationCircleIcon,
  ExclamationIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import Timeline from "./Timeline";
import { DuActivityIndicator } from "components/DuActivityIndicator/DuActivityIndicator";
import { Card } from "./Card";
import {
  getPaymentIconFromOrderState,
  getPosIconFromOrderState,
  getPaymentTextFromOrderState,
  getPosTextFromOrderState,
} from "../../lenses/payment";

const printItems = (items, currency) =>
  items
    .map(
      ({ name, description, amount, price }) =>
        amount +
        "x " +
        name +
        ` (${printCurrency(price, currency)})` +
        (description ? `\n   ${description}` : "")
    )
    .join("\n");

const Component = ({ payment, loading }) => {
  return (
    <>
      {payment !== undefined && payment.order !== undefined && !loading && (
        <div className="full-screenable-node flex flex-col">
          <div className="pb-5">
            <h3 className="text-xl leading-6 font-bold text-gray-900">
              Bezahlung {payment.id}
            </h3>
          </div>

          <Card
            title={"Zahlungsdetails"}
            items={[
              {
                title: "Gesamtbetrag",
                content: `${printCurrency(payment.total, payment.currency)}`,
              },
              {
                title: "Trinkgeld",
                content: `${printCurrency(payment.tip, payment.currency)}`,
              },
            ]}
          />

          <Card
            title={"Status"}
            items={[
              {
                title: (
                  <>
                    <img className="h-5 w-5 inline -mt-2" src="/money.png" />{" "}
                    Rechnung beglichen
                  </>
                ),
                content: (
                  <>
                    <img
                      className="w-3 h-3 inline -mt-1"
                      src={getPaymentIconFromOrderState(payment.orderStatus)}
                    />{" "}
                    {getPaymentTextFromOrderState(payment.orderStatus)}
                  </>
                ),
              },
              {
                title: (
                  <>
                    <img className="h-5 w-5 inline -mt-2" src="/computer.png" />{" "}
                    Kasse benachrichtigt
                  </>
                ),
                content: (
                  <>
                    <img
                      className="w-3 h-3 inline -mt-1"
                      src={getPosIconFromOrderState(payment.orderStatus)}
                    />{" "}
                    {getPosTextFromOrderState(payment.orderStatus)}
                  </>
                ),
              },
            ]}
          />

          <Card
            title={"Zahlungsmethode"}
            items={[
              {
                title: "Zahlungsart",
                content: payment.paymentMethod,
              },
              {
                title: "Zahlungs-ID",
                content: payment.paymentProcessorId,
              },
              {
                title: "Rechnungs-ID",
                content: payment.invoiceId,
              },
            ]}
          />

          <Card
            title={"Bestellung"}
            items={[
              {
                title: "Bestellungs-ID",
                content: payment.order?.id,
              },
              {
                title: "Tisch",
                content: payment.order?.table?.name,
              },
              {
                title: "Vollständig gezahlt",
                content: payment.order?.fullyPayed ? "ja" : "nein",
              },
              {
                title: "Gezahlte Bestellung",
                content: (
                  <pre>
                    {printItems(
                      (payment.partialOrder?.items || []).map(
                        ({ orderItemId, amount }) => ({
                          ...payment.order.order.items.find(
                            ({ orderItemId: id2 }) => id2 === orderItemId
                          ),
                          amount,
                        })
                      ),
                      payment.currency
                    )}
                  </pre>
                ),
              },
              {
                title: "Bestellung des Tisches",
                content: (
                  <pre>
                    {printItems(
                      payment.order.order.items || [],
                      payment.currency
                    )}
                  </pre>
                ),
              },
            ]}
          />

          <Card
            title="Verlauf"
            items={[
              {
                title: "",
                content: (
                  <Timeline
                    items={payment.states?.map(
                      ({ id, state, message, failure, done, created }) => ({
                        id,
                        content: labelify(state),
                        body: message,
                        date: dateToText(created),
                        icon: failure
                          ? ExclamationIcon
                          : done
                          ? CheckCircleIcon
                          : InformationCircleIcon,
                        color: failure ? "text-red-500" : "text-green-500",
                      })
                    )}
                  />
                ),
              },
            ]}
          />
        </div>
      )}
      {loading && (
        <div className="mt-4 mb-4 flex justify-center">
          <DuActivityIndicator local />
        </div>
      )}
    </>
  );
};

Component.propTypes = {
  payment: PropTypes.object,
  loading: PropTypes.bool,
};

export default Component;
